import {AUDIOBOOK_TRANSLATION, AudiobookTranslationKey} from "./Audiobook"
import {LOGIN_TRANSLATION, LoginTranslationKey} from "./Login"
import {PROFILE_TRANSLATION, ProfileTranslationKey} from "./Profile"
import {NAV_BAR_TRANSLATION, NavBarTranslationKey} from "./NavBar"
import {SERIES_TRANSLATION, SeriesTranslationKey} from "./Series"
import {SEARCH_TRANSLATION, SearchTranslationKey} from "./Search"
import {ERROR_CODE_TRANSLATION, ErrorCodesTranslationKey} from "./ErrorCodes";
import {useSelector} from "react-redux";
import {currentLanguage} from "./languageSlice"
import {useEffect, useState} from "react";
import {HOME_VIEW_TRANSLATION, HomeViewTranslationKey} from "./HomeView";
import {STATISTICS_TRANSLATION, StatisticsTranslationKey} from "./Statistics";
import {WISHLIST_TRANSLATION, WishlistTranslationKey} from "./Wishlist";

export const ALL_LANGUAGES = ["english", "german"] as const
type LanguageTuple = typeof ALL_LANGUAGES
export type Language = LanguageTuple[number]

export type Translation<T extends string> = Record<Language, Record<T, string>>

type OtherTranslationKey =
    | "german"
    | "english"
    | "spanish"
    | "search"
    | "loading"
    | "didWork"
    | "back"
    | "edit"
    | "save"
    | "saved"
    | "delete"
    | "deleted"
    | "addEntry"

const OTHER_TRANSLATION: Translation<OtherTranslationKey> = {
    english: {
        didWork: "did work",
        german: "German",
        english: "English",
        spanish: "Spanish",
        search: "Search",
        loading: "Loading",
        back: "back",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        delete: "Delete",
        deleted: "Deleted",
        addEntry: "Add Entry",
    },
    german: {
        didWork: "Hat geklappt",
        german: "Deutsch",
        english: "Englisch",
        spanish: "Spanisch",
        search: "Suche",
        loading: "Lädt",
        back: "zurück",
        edit: "Bearbeiten",
        save: "Speichern",
        saved: "Gespeichert",
        delete: "Löschen",
        deleted: "Gelöscht",
        addEntry: "Eintrag hinzufügen",
    },
}

export type TranslationKey =
    | LoginTranslationKey
    | AudiobookTranslationKey
    | SeriesTranslationKey
    | ProfileTranslationKey
    | NavBarTranslationKey
    | SearchTranslationKey
    | OtherTranslationKey
    | ErrorCodesTranslationKey
    | HomeViewTranslationKey
    | StatisticsTranslationKey
    | WishlistTranslationKey

export default function useTranslation(): (key: TranslationKey) => string {
    const lang = useSelector(currentLanguage)
    const [currentTranslations, setCurrentTranslations] = useState(TRANSLATIONS["english"])

    useEffect(() => {
        setCurrentTranslations(TRANSLATIONS[lang])
    }, [lang])

    return (key: TranslationKey) => currentTranslations[key]
}

export function translationKeyExists(key: string): boolean {
    return TRANSLATIONS["english"][key as TranslationKey] !== undefined
}

const TRANSLATIONS: Translation<TranslationKey> = {
    english: {
        ...HOME_VIEW_TRANSLATION.english,
        ...ERROR_CODE_TRANSLATION.english,
        ...AUDIOBOOK_TRANSLATION.english,
        ...SERIES_TRANSLATION.english,
        ...LOGIN_TRANSLATION.english,
        ...PROFILE_TRANSLATION.english,
        ...NAV_BAR_TRANSLATION.english,
        ...SEARCH_TRANSLATION.english,
        ...STATISTICS_TRANSLATION.english,
        ...WISHLIST_TRANSLATION.english,
        ...OTHER_TRANSLATION.english,
    },
    german: {
        ...HOME_VIEW_TRANSLATION.german,
        ...ERROR_CODE_TRANSLATION.german,
        ...AUDIOBOOK_TRANSLATION.german,
        ...SERIES_TRANSLATION.german,
        ...LOGIN_TRANSLATION.german,
        ...PROFILE_TRANSLATION.german,
        ...NAV_BAR_TRANSLATION.german,
        ...SEARCH_TRANSLATION.german,
        ...STATISTICS_TRANSLATION.german,
        ...WISHLIST_TRANSLATION.german,
        ...OTHER_TRANSLATION.german,
    },
}
