import ic_clock from "../img/clock.png"
import ic_not_found from "../img/page_not_found.png"
import {Spinner} from "../components/util/Loading";

export function DataNotFound(props: {
    description: string,
}) {
    return (
        <div className="flex h-screen dark:bg-gray-800 dark:text-white">
            <div className="m-auto flex flex-col items-center">
                <h2 className="text-2xl font-semibold leading-tight mb-3">
                    {props.description}
                </h2>
                <img src={ic_not_found} alt="not found" />
            </div>
        </div>
    )
}
export function LoadingView(props: {
    description: string,
}) {
    return (
        <div className="flex h-screen">
            <div className="m-auto flex flex-col items-center">
                <h2 className="text-2xl font-semibold leading-tight mb-3">
                    {props.description}
                </h2>
                <Spinner icon={ic_clock}/>
            </div>
        </div>
    )
}