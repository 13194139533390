import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./store";
import {Second} from "../utils/constant";


export type Notification = { title: string, text: string, type: "info" | "success" | "error" | "loading", duration: number };
export type SuccessNotification = { title?: string, text?: string, duration?: number };
export type ErrorNotification = { title?: string, text?: string, duration?: number };


interface NotificationState {
    value: Notification | null
}

const initialState: NotificationState = {
    value: null
}


export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<Notification | null>) => {
            state.value = action.payload
        },
        setSuccessNotification: (state, action: PayloadAction<SuccessNotification>) => {
            const {title, text, duration} = action.payload;
            state.value = {
                title: title ?? "Success",
                text: text ?? "",
                type: "success",
                duration: duration ?? 5 * Second
            }
        },
        setErrorNotification: (state, action: PayloadAction<ErrorNotification>) => {
            const {title, text, duration} = action.payload;
            state.value = {
                title: title ?? "Error",
                text: text ?? "",
                type: "error",
                duration: duration ?? 5 * Second
            }
        }
    }
})

export const {
    setNotification,
    setSuccessNotification,
    setErrorNotification
} = notificationSlice.actions

export const selectNotification = (state: RootState) => state.notification.value

export default notificationSlice.reducer
