import React, {Fragment, useEffect, useState} from 'react'
import {createBrowserRouter, NavLink, Outlet, RouterProvider} from "react-router-dom"
import { ScrollRestoration } from "react-router-dom";

import ic_icon from "./img/icon.png"
import {DataNotFound} from "./views/ErrorView"
import AudiobookList from "./views/audiobook/Audiobooks"
import LoginView from "./views/LoginView"
import SeriesList from "./views/series/Series"
import {AudiobookDetailsView} from "./views/audiobook/Audiobook"
import {ProfileView} from "./views/Profile"
import {Language} from "./i18n"
import {AdminView} from "./views/admin/AdminView"
import {usePostUsersByIdMutation, UserWithToken} from "./api/generatedApi";
import {selectUser, setLoggedInUser, userKey} from "./store/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store/store";
import {setErrorNotification, setNotification} from "./store/notificationSlice";
import FooterComponent from "./components/FooterComponent";
import {currentLanguage, setLanguage} from "./i18n/languageSlice";
import useTranslation from "./i18n";
import HomeView from "./views/HomeView";
import {Menu, Transition} from "@headlessui/react";
import {FaMoon, FaSun, HiLanguage} from "react-icons/all";
import {classNames} from "./utils/util";
import {getErrorMessage} from "./components/util/ErrorComponent";
import {
    ErrorNotificationComponent, InfoNotificationComponent,
    LoadingNotificationComponent, SuccessNotificationComponent
} from "./components/util/NotificationComponent";
import SeriesDetailsView from "./views/series/SeriesDetails";
import {AudiobookEditor} from "./views/audiobook/AudiobookEditor";
import {SeriesEditor} from "./views/series/SeriesEditor";
import {StatisticsView} from "./views/StatisticsView";

function App() {
    const dispatch = useDispatch()
    const loggedInUser = useSelector(selectUser)

    useEffect(() => {
        if (loggedInUser) dispatch(setLanguage(loggedInUser.language as Language))
    }, [loggedInUser])

    // on load check if we find user information in local storage
    useEffect(() => {
        const serializedUser = localStorage.getItem(userKey)
        if (serializedUser) dispatch(setLoggedInUser(JSON.parse(serializedUser)))
    }, [])

    // notification stuff
    const notification = useSelector((state: RootState) => state.notification.value)
    // remove notification after it is eased out
    useEffect(() => {
        if (!notification) return
        const timeId = setTimeout(() => dispatch(setNotification(null)), notification.duration + 500)
        return () => clearTimeout(timeId)
    }, [notification])
    // decide what to render - notification wise
    let notificationComponent = null
    if (notification !== null) {
        switch (notification.type) {
            case "success":
                notificationComponent = <SuccessNotificationComponent
                    title={notification.title} text={notification.text} duration={notification.duration}/>
                break
            case "error":
                notificationComponent = <ErrorNotificationComponent
                    title={notification.title} text={notification.text} duration={notification.duration}/>
                break
            case "info":
                notificationComponent = <InfoNotificationComponent
                    title={notification.title} text={notification.text} duration={notification.duration}/>
                break
            case "loading":
                notificationComponent = <LoadingNotificationComponent
                    title={notification.title} text={notification.text} duration={notification.duration}/>
                break
        }
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RootElement/>,
            errorElement: <DataNotFound description="not found"/>,
            children: [
                {path: "", element: <HomeView/>},
                {path: "audiobooks", element: <AudiobookList/>},
                {path: "series", element: <SeriesList/>},
                {path: "audiobooks/:audiobookId", element: <AudiobookDetailsView/>},
                {path: "audiobooks/:audiobookId/edit", element: <AudiobookEditor/>},
                {path: "audiobooks/add", element: <AudiobookEditor/>},
                {path: "series/:seriesId", element: <SeriesDetailsView/>},
                {path: "series/:seriesId/edit", element: <SeriesEditor/>},
                {path: "series/add", element: <SeriesEditor/>},
                {path: "statistics", element: <StatisticsView/>},
                {path: "profile", element: <ProfileView/>},
                {path: "admin", element: <AdminView/>},
            ]
        },
    ])

    if (!loggedInUser) {
        return <div className={"h-screen w-screen"}>
            {notificationComponent}
            <LoginView/>
        </div>
    }

    return <div className={"h-screen w-screen"}>
        {notificationComponent}
        <RouterProvider router={router}/>
    </div>
}

const Logo = () => {
    return (
        <NavLink to={`/`}>
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <img className="p-2" src={ic_icon} alt={"app icon"} width="48px"/>
                <span className="font-semibold text-xl tracking-tight">AudioPort</span>
            </div>
        </NavLink>
    )
}

function RootElement() {
    const dispatch = useDispatch()
    const t = useTranslation()

    const loggedInUser: UserWithToken = useSelector(selectUser)!!

    const [menuOpen, setMenuOpen] = useState(false)

    const navLinkActiveCls = "border-b-2 border-white text-lg"
    const navLinkCls = "block mt-4 md:inline-block md:mt-0 mr-2 md:mr-4 hover:text-gray-300"

    return (
        <div className="bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-200 min-h-screen flex flex-col">
            <div>
                <nav
                    className="flex items-center justify-between flex-wrap bg-sky-800 h-min shadow-md shadow-gray-400 dark:shadow-gray-900">
                    <div className="w-full md:w-auto flex items-center justify-between flex-wrap pr-2 md:p-2">
                        <Logo/>
                        <MenuButton toggleMenu={() => setMenuOpen(!menuOpen)}/>
                    </div>
                    <div className={`${menuOpen ? "block w-full pb-3 " : "hidden"} text-white z-[1] bg-sky-800 rounded-b fixed right-0 top-12 md:top-20 md:static md:flex md:items-center grow md:w-auto`}>
                        <div className="text-sm flex flex-col items-end pr-4 md:pr-0 md:block md:grow">
                            <NavLink to={`/`}
                                     className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                     onClick={() => setMenuOpen(false)}>
                                {t("navBarHome")}
                            </NavLink>

                            <NavLink to={`/audiobooks`}
                                     className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                     onClick={() => setMenuOpen(false)}>
                                {t("navBarLibrary")}
                            </NavLink>

                            <NavLink to={`/series`}
                                     className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                     onClick={() => setMenuOpen(false)}>
                                {t("navBarSeries")}
                            </NavLink>
                            <NavLink to={`/statistics`}
                                     className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                     onClick={() => setMenuOpen(false)}>
                                {t("navBarStatistics")}
                            </NavLink>

                            <NavLink to={`/profile`}
                                     className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                     onClick={() => setMenuOpen(false)}>
                                {t("navBarProfile")}
                            </NavLink>
                            {loggedInUser.isAdmin &&
                                <NavLink to={`/admin`}
                                         className={({isActive}) => `${isActive ? navLinkActiveCls : ""} ${navLinkCls}`}
                                         onClick={() => setMenuOpen(false)}>
                                    {t("navBarAdmin")}
                                </NavLink>
                            }
                        </div>
                        <div className="flex items-end justify-end pl-4 pr-4 pt-2 md:pl-0">
                            <DarkModeToggleButton/>
                            <LanguageSelect/>
                            <button
                                className="block text-sm ml-2 px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
                                onClick={() => dispatch(setLoggedInUser(null))}>
                                {t("navBarLogout")}
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
            <div className={"flex-grow w-full text-gray-700 dark:text-white transition"}>
                <Outlet/>
            </div>
            <FooterComponent/>
            <ScrollRestoration
                getKey={(location, matches) => {
                    return location.pathname;
                }}
            />
        </div>
    )
}

function MenuButton(props: { toggleMenu: () => void }) {
    return (
        <div className="md:hidden">
            <button
                className="flex items-center px-3 py-2 border rounded text-indigo-200 border-white hover:text-white hover:border-white"
                onClick={() => props.toggleMenu()}>
                <svg
                    className="fill-current h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                </svg>
            </button>
        </div>
    )
}

function DarkModeToggleButton() {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("theme") === 'dark')

    useEffect(() => {
        updateDarkMode(darkMode)
        storeDarkMode(darkMode)
    }, [darkMode])

    const updateDarkMode = function (dark: boolean) {
        if (dark) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    const storeDarkMode = function (dark: boolean) {
        if (dark) {
            localStorage.setItem("theme", "dark")
        } else {
            localStorage.removeItem("theme")
        }
    }

    return (
        <button
            className="block text-sm mr-2 px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0"
            onClick={() => setDarkMode(!darkMode)}
        >
            {darkMode ? <FaSun/> : <FaMoon/>}
        </button>
    )
}


export default App


function LanguageSelect() {
    const dispatch = useDispatch()
    const t = useTranslation()
    const lang = useSelector(currentLanguage)
    const loggedInUser = useSelector(selectUser)!!

    const [updateUser, {error}] = usePostUsersByIdMutation()
    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({text: t(getErrorMessage(error))}));
            return;
        }
    }, [error])

    return <Menu as="div" className="relative inline-block text-left">
        <div>
            <Menu.Button
                className="inline-flex text-sm px-4 py-2 leading-none border rounded border-white hover:border-transparent hover:text-indigo-500 hover:bg-white lg:mt-0 justify-center">
                <HiLanguage aria-hidden="true"/>
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items
                className="absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    <Menu.Item>
                        {({active}) => (
                            <span
                                className={classNames(
                                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                                    lang === "english" ? "text-blue-500" : "",
                                    'block px-4 py-2 text-sm')}
                                onClick={() => {
                                    dispatch(setLanguage("english"))
                                    updateUser({updateUser: {language: "english"}, id: loggedInUser.id})
                                }}>
                                {t("english")}
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({active}) => (
                            <span
                                className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    lang === "german" ? "text-blue-500" : "",
                                    'block px-4 py-2 text-sm')}
                                onClick={() => {
                                    dispatch(setLanguage("german"))
                                    updateUser({updateUser: {language: "german"}, id: loggedInUser.id})
                                }}>
                                {t("german")}
                            </span>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}
