import React, {useEffect, useState} from "react"
import ic_icon from "../img/icon.png"
import {usePostLoginMutation} from "../api/generatedApi";
import {useDispatch} from "react-redux";
import {setLoggedInUser} from "../store/userSlice";
import {setErrorNotification, setNotification, setSuccessNotification} from "../store/notificationSlice";
import {getErrorMessage} from "../components/util/ErrorComponent";
import {Second} from "../utils/constant";
import useTranslation from "../i18n";

export default function LoginView() {
    const dispatch = useDispatch()
    const t = useTranslation()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [login, {data: newUser, isLoading, error}] = usePostLoginMutation()

    const startLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        login({loginCredentials: {username, password}})
    }
    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({title: t("loginErrorTitle"), text: t(getErrorMessage(error))}));
            dispatch(setLoggedInUser(null));
            return;
        }
        if (isLoading)  {
            dispatch(setNotification({
                title: t("loading"),
                text: t("loginLoading"),
                type: "loading", duration: Second
            }))
        }
        if (newUser) {
            dispatch(setSuccessNotification({text: t("loginWelcomeText")}));
            dispatch(setLoggedInUser(newUser));
            return;
        }
    }, [newUser, isLoading, error])

    return (
        <div className="flex bg-gray-100 h-screen">
            <div className="m-auto w-full max-w-xs ">
                <form
                    className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={startLogin}
                >
                    <img
                        className="m-auto p-2"
                        src={ic_icon}
                        alt={"app icon"}
                        width="128px"
                    />

                    <div className="mb-4">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="username"
                        >
                            {t("loginUsername")}
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder={t("loginUsername")!!}
                            value={username}
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                            id="username-input"
                        />
                    </div>
                    <div className="mb-6">
                        <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="password"
                        >
                            {t("loginPassword")}
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                            id="password-input"
                            type="password"
                            placeholder="******************"
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className={
                                "bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            }
                            type="submit"
                            disabled={isLoading}
                        >
                            {t("loginSignInBtn")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
