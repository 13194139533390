import React from "react"
import {btnPrimCls, cardCls} from "../../styles"
import {Link} from "react-router-dom"
import {LoadingView} from "../ErrorView"
import {ErrorComponent} from "../../components/util/ErrorComponent";
import {Series, useGetSeriesQuery, UserWithToken} from "../../api/generatedApi";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/userSlice";
import useTranslation from "../../i18n";
import {SeriesSearch, getTextCondition} from "../../components/series/SeriesSearch";
import {selectSeriesSearch} from "../../store/seriesSearchSlice";

export function SeriesCard(props: {
    series: Series,
}) {
    const t = useTranslation()
    const {series} = props

    const loggedInUser: UserWithToken | null = useSelector(selectUser)

    return (
        <div className={`${cardCls} mt-3 mb-3 p-3`}>
            <div className="grid justify-items-stretch grid-cols-2">
                <div>
                    <Link to={`/series/${series.id}`} key={series.id}>
                        {series.name} ({series.elements.length} {t("seriesTitles")})
                    </Link>
                </div>
                <div className="justify-self-end">
                    {loggedInUser && loggedInUser.isAdmin && <Link to={`/series/${series.id}/edit`} className={`${btnPrimCls} py-2 mr-2`}>
                        Edit
                    </Link>}
                    <Link to={`/series/${series.id}`} key={series.id} className={btnPrimCls}>
                        +
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default function SeriesList() {
    const t = useTranslation()
    const searchOptions = useSelector(selectSeriesSearch)

    const {data: seriesList, error: seriesError} = useGetSeriesQuery()

    if (seriesError) {
        return <ErrorComponent error={seriesError}/>
    }

    if (!seriesList) return <LoadingView description={"loading"}/>

    const seriesFiltered = seriesList.filter((a) =>
        getTextCondition(searchOptions.text, a)
    )

    const seriesToShow = [...seriesFiltered].sort((b, a) => a.name > b.name ? -1 : 1)

    return (<div>
        <div className="flex justify-center">
            <h1 className="m-3 text-2xl leading-tight">
                {t("series")}
            </h1>
        </div>
        <div className="max-w-5xl mx-auto m-3">
            <SeriesSearch/>
            {seriesToShow.map((ele, index) =>
                <SeriesCard key={index} series={ele} />
            )}
        </div>
    </div>)
}
