import {cardCls} from "../../styles";
import {Link, useNavigate, useParams} from "react-router-dom";
import {InputComponent} from "../../components/ui/InputComponent";
import React, {useEffect, useState} from "react";
import useTranslation from "../../i18n";
import {useDispatch} from "react-redux";
import {
    UpdateSeries, UpdateSeriesElement, useDeleteSeriesByIdMutation,
    useLazyGetSeriesByIdQuery,
    usePostSeriesMutation,
    usePutSeriesByIdMutation
} from "../../api/generatedApi";
import {ButtonComponent} from "../../components/ui/ButtonComponent";
import {LabelComponent} from "../../components/ui/LabelComponent";
import {FiTrash} from "react-icons/all";
import {setErrorNotification, setSuccessNotification} from "../../store/notificationSlice";

function SeriesEditorElement(props: {
    element: UpdateSeriesElement,
    removeElement: () => void,
    updateElement: (element: UpdateSeriesElement) => void
}) {
    const {element} = props;
    return (
        <tr className="grid grid-cols-12 rounded px-2 py-0.5">
            <td className="col-span-1 mx-1">
                <input className="dark:bg-slate-600 rounded px-2 py-1 w-full"
                       value={element.position}
                       onChange={(e) => {
                           props.updateElement({...element, position: parseInt(e.target.value) || 0})
                       }}/>
            </td>
            <td className="col-span-5 mx-1">
                <input className="dark:bg-slate-600 rounded w-full px-2 py-1"
                       value={element.audiobookId || ''}
                       onChange={(e) => {
                           props.updateElement({...element, audiobookId: e.target.value})
                       }}/></td>
            <td className="col-span-5 mx-1">
                <input className="dark:bg-slate-600 rounded w-full px-2 py-1 cursor-not-allowed"
                       value={element.id || ''} disabled={true} readOnly={true}/></td>
            <td className="col-span-1 mx-1">
                <div className="h-full"><p
                    className=" h-full bg-red-800 border border-red-800 rounded hover:border-gray-300 w-8 shadow-lg"
                    onClick={() => {
                        props.removeElement()
                    }}><FiTrash className="m-auto my-1.5"/></p></div>
            </td>
        </tr>
    )
}

export function SeriesEditor() {
    const t = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {seriesId} = useParams();

    const [fetchSeries, {data: series, error: seriesError}] = useLazyGetSeriesByIdQuery();
    const [createSeries, {data: createdSeries, error: createSeriesError}] = usePostSeriesMutation();
    const [updateSeries, {data: updatedSeries, error: updateSeriesError}] = usePutSeriesByIdMutation();
    const [deleteSeriesQuery, {data: deletedSeries, error: deleteSeriesError}] = useDeleteSeriesByIdMutation();

    const [name, setName] = useState<string>(series ? series.name : "")
    const [description, setDescription] = useState<string>(series && series.description ? series.description : "")
    const [seriesElements, setSeriesElements] = useState<UpdateSeriesElement[]>(series ? series.elements : [])

    useEffect(() => {
        if (seriesId) {
            fetchSeries({id: seriesId});
        }
    }, [seriesId]);

    useEffect(() => {
        if (seriesError !== undefined) {
            dispatch(setErrorNotification({text: "Loading Series Failed"}));
            console.log(seriesError);
            return;
        }
        if (series) {
            setName(series.name);
            setDescription(series.description ? series.description : "");
            setSeriesElements(series.elements);
        }
    }, [series, seriesError]);

    useEffect(() => {
        if (createSeriesError !== undefined) {
            dispatch(setErrorNotification({text: "Creating Series Failed"}));
            console.log(createSeriesError);
        }
        if (createdSeries) {
            dispatch(setSuccessNotification({text: "Series created."}));
        }
    }, [createdSeries, createSeriesError]);

    useEffect(() => {
        if (updateSeriesError !== undefined) {
            dispatch(setErrorNotification({text: "Updating Series Failed"}));
            console.log(updateSeriesError);
        }
        if (updatedSeries !== undefined) {
            dispatch(setSuccessNotification({text: "Series updated."}));
        }
    }, [updatedSeries, updateSeriesError]);

    useEffect(() => {
        if (deleteSeriesError !== undefined) {
            dispatch(setErrorNotification({text: "Deleting Series Failed"}));
            console.log(deleteSeriesError);
        }
        if (deletedSeries !== undefined) {
            dispatch(setSuccessNotification({text: "Series deleted."}));
            navigate("/series");
        }
    }, [deletedSeries, deleteSeriesError]);

    function saveSeries(e: { preventDefault: () => void }) {
        e.preventDefault();
        const seriesData: UpdateSeries = {
            name: name,
            description: description,
            elements: seriesElements
        };
        if (series === undefined) {
            createSeries({updateSeries: seriesData});
        } else {
            updateSeries({id: series.id, updateSeries: seriesData});
        }
    }

    function deleteSeries(e: { preventDefault: () => void }) {
        e.preventDefault();
        if (!series) return;
        deleteSeriesQuery({id: series.id});
    }

    return (
        <div>
            <div className="flex justify-center">
                <h1 className="text-2xl leading-tight m-3">{series ? t("seriesSeriesEdit") : t("seriesAdd")}</h1>
            </div>
            <div className={`${cardCls} container m-auto my-5 p-5 pt-3`}>
                {series && <p className="mb-3 hover:underline">
                    <Link to={`/series/${series.id}`}>&lt; {t("back")}</Link>
                </p>}

                <form>
                    <div className="mb-10 grid grid-cols-4">
                        <div className="col-span-1">
                            <InputComponent value={series ? series.id : "-"} id="id-input" label="ID"
                                            disabled={true}/>
                        </div>
                        <div className="ml-2 col-span-1">
                            <InputComponent value={name} id="name-input"
                                            label={t("seriesName")}
                                            onChange={(value) => setName(value)}/>
                        </div>
                        <div className="ml-2 col-span-2">
                            <InputComponent value={description} id="description-input"
                                            label={t("seriesDescription")}
                                            onChange={(value) => setDescription(value)}/>
                        </div>
                        <div className="col-span-4 mt-4">
                            <LabelComponent text={t("seriesTitles")}/>
                            <table>
                                <thead>
                                <tr className="grid grid-cols-12">
                                    <th className="col-span-1">Position</th>
                                    <th className="col-span-5">Audiobook ID</th>
                                    <th className="col-span-5">Element ID</th>
                                </tr>
                                </thead>
                                <tbody>
                                {seriesElements.map((element, index) =>
                                    <SeriesEditorElement key={index} element={element}
                                                         removeElement={() => {
                                                             const newElements = [...seriesElements];
                                                             newElements.splice(index, 1);
                                                             setSeriesElements(newElements);
                                                         }}
                                                         updateElement={(newElement) => {
                                                             const newElements = [...seriesElements];
                                                             newElements.splice(index, 1, newElement);
                                                             setSeriesElements(newElements);
                                                         }}
                                    />
                                )}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td className="text-center">
                                        <button className="w-20 px-6 py-1 mt-2 bg-gray-600 shadow-lg rounded transition"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setSeriesElements([...seriesElements, {audiobookId: "", position: 0}])
                                                }}
                                        >+
                                        </button>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <ButtonComponent text={t("save")} onClick={(e) => saveSeries(e)}/>
                    <ButtonComponent text={t("delete")} onClick={(e) => deleteSeries(e)}/>
                </form>
            </div>
        </div>
    )
}