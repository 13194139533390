
import {
    generatedApi,
} from "./generatedApi";

// even through setting a image content type in open api the response handler is parsing it as json
const enhancedApi = generatedApi.enhanceEndpoints({
    endpoints: {
        getAudibleAudibleThumbnailByAsin: ({
            query: (queryArg) => ({
                url: `/audible/audible-thumbnail/${queryArg.asin}`,
                responseHandler: (response) => response.blob(),
            }),
        }),
    },
})

export const {useLazyGetAudibleAudibleThumbnailByAsinQuery} = enhancedApi