import {UserWithToken} from "../api/generatedApi";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";

export const userKey = "audioport.user"
interface UserState {
    value: UserWithToken | null
}

// Define the initial state using that type
const initialState: UserState = {
    value: null
}


export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLoggedInUser: (state, action: PayloadAction<UserWithToken | null>) => {
            const user = action.payload
            if (user) {
                state.value = user
                localStorage.setItem(userKey, JSON.stringify(user))
            } else {
                state.value = user
                localStorage.removeItem(userKey)
                window.location.reload()
            }
        },
    }
})

export const {setLoggedInUser} = userSlice.actions

export const selectUser = (state: RootState) => state.user.value

export default userSlice.reducer
