import React from "react";
import {LabelComponent} from "./LabelComponent";

export function InputComponent(props: {
    disabled?: boolean,
    className?: string,
    value: string | number,
    label?: string,
    id: string,
    onChange?: (value: string) => void,
    type?: string,
}) {
    const {
        disabled,
        className,
        value,
        label,
        id,
        onChange,
        type
    } = props

    return (<>
        {label && <LabelComponent text={label} for={id}/>}
        <input
            className={`appearance-none rounded w-full py-2 px-3 ${!disabled ? "bg-gray-200 dark:bg-slate-600" : "bg-gray-300 dark:bg-gray-500"} text-gray-700 dark:text-gray-200 leading-tight focus:outline-none focus:shadow-outline ${className}`}
            id={id}
            value={value} disabled={disabled}
            onChange={(e) => {
                e.preventDefault();
                if (onChange !== undefined) {
                    onChange(e.target.value);
                }
            }}
            type={type}
        />
    </>)
}
