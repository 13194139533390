import {Translation} from "./index"

export type SeriesTranslationKey =
    | "series"
    | "seriesTitles"
    | "seriesTotalDuration"
    | "seriesTitleCount"
    | "seriesProgress"
    | "seriesSeriesEdit"
    | "seriesAdd"
    | "seriesDescription"
    | "seriesName"

export const SERIES_TRANSLATION: Translation<SeriesTranslationKey> = {
    english: {
        series: "Series",
        seriesTitles: "Titles",
        seriesTotalDuration: "Total Duration",
        seriesTitleCount: "Titles",
        seriesProgress: "Progress",
        seriesSeriesEdit: "Edit Series",
        seriesAdd: "Add Series",
        seriesDescription: "Description",
        seriesName: "Name",
    },
    german: {
        series: "Buchreihen",
        seriesTitles: "Bücher",
        seriesTotalDuration: "Gesamtdauer",
        seriesTitleCount: "Titel",
        seriesProgress: "Fortschritt",
        seriesSeriesEdit: "Buchreihe bearbeiten",
        seriesAdd: "Buchreihe erstellen",
        seriesDescription: "Beschreibung",
        seriesName: "Name",
    }
}
