import useTranslation from "../../i18n";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ErrorComponent, getErrorMessage} from "../../components/util/ErrorComponent";
import {LoadingView} from "../ErrorView";
import {cardCls} from "../../styles";
import {formatDuration, formatString} from "../../utils/util";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../store/userSlice";
import {isFinished} from "../../utils/audiobookUtils";
import {
    Audiobook, AudiobookUserInfo,
    useGetAudiobooksQuery,
    useGetUsersByIdUserInfoQuery, useLazyGetAudiobooksByIdUserInfoQuery,
    useLazyGetSeriesByIdQuery, usePutAudiobooksByIdUserInfoMutation
} from "../../api/generatedApi";
import {setErrorNotification, setSuccessNotification} from "../../store/notificationSlice";
import {useImageHook} from "../../utils/imageHook";
import ic_check from "../../img/checkmark.png";
import ic_cross from "../../img/cross.png";


export function SeriesBookCard(props: { audiobook: Audiobook, info: AudiobookUserInfo | undefined }) {
    const dispatch = useDispatch()
    const {audiobook, info} = props
    const [userInfo, setUserInfo] = useState(info)

    const [fetchInfo, {data: newUserInfo}] = useLazyGetAudiobooksByIdUserInfoQuery()
    const [updateInfo, {data: updateUserInfoData, error: updateUserInfoError}] = usePutAudiobooksByIdUserInfoMutation()
    const imgSrc = useImageHook(audiobook.id, 128)

    useEffect(() => {
        if (updateUserInfoError === undefined) {
            return
        }
        dispatch(setErrorNotification({text: "Toggle Failed"}));
        getErrorMessage(updateUserInfoError);
    }, [updateUserInfoError])

    useEffect(() => {
        if (updateUserInfoData === undefined) {
            return
        }
        dispatch(setSuccessNotification({text: `Toggle ${audiobook.name} Finished`}));
        fetchInfo({id: audiobook.id})
    }, [updateUserInfoData])

    useEffect(() => {
        if (newUserInfo === undefined) {
            return
        }
        setUserInfo(newUserInfo)
    }, [newUserInfo])

    const audiobookFinished = userInfo ? isFinished(audiobook, userInfo) : false

    const narratorsText = audiobook.narrators.map((narrator) => narrator.name).join(", ")

    return (
        <div className={`${cardCls} m-3 ml-1  flex justify-items-start hover:ring-gray-400 hover:ring-2`}>
            <div className="shrink-0">
                <Link to={`/audiobooks/${audiobook.id}`}>
                    <img className="h-32 w-32 rounded-l" src={imgSrc} alt="Icon"/>
                </Link>
            </div>

            <div className="ml-5 py-2 min-w-0">
                <Link className="text-xl leading-tight hover:underline" to={`/audiobooks/${audiobook.id}`}>
                    {formatString(audiobook.name, 25)}
                </Link>
                <p className="truncate">{formatString(narratorsText, 25)}</p>
                <p className="truncate">{formatDuration(audiobook.lengthMsec)}</p>
            </div>

            <div className="flex shrink-0 mt-2 mr-2 grow justify-end">
                <img src={audiobookFinished ? ic_check : ic_cross}
                     className="h-6 w-6 hover:cursor-pointer" alt="Finished"
                     onClick={(_) => {
                         if (audiobookFinished) {
                             updateInfo({id: audiobook.id, updateAudiobookUserInfo: {progressMSec: 0}})
                         } else {
                             updateInfo({
                                 id: audiobook.id,
                                 updateAudiobookUserInfo: {progressMSec: audiobook.lengthMsec}
                             })
                         }
                     }}
                />
            </div>
        </div>
    )
}


export default function SeriesDetailsView() {
    const t = useTranslation()
    const dispatch = useDispatch();

    const {seriesId} = useParams()
    const loggedInUser = useSelector(selectUser)!!


    const [fetchSeriesSingle, {data: series, error: seriesError}] = useLazyGetSeriesByIdQuery()

    const {data: audiobooks, error: audiobookError} = useGetAudiobooksQuery()
    const {data: audiobookUserInfos, error: infoError} = useGetUsersByIdUserInfoQuery({id: loggedInUser.id})

    useEffect(() => {
        if (seriesId === undefined) return

        fetchSeriesSingle({id: seriesId})
    }, [seriesId])

    useEffect(() => {
        if (audiobookError !== undefined) {
            dispatch(setErrorNotification({text: "Loading Audiobooks failed"}));
        }
    }, [audiobookError]);

    useEffect(() => {
        if (infoError !== undefined) {
            dispatch(setErrorNotification({text: "Loading User Info failed"}));
        }
    }, [infoError]);

    if (seriesError) return <ErrorComponent error={seriesError}/>
    if (!series || !audiobooks || !audiobookUserInfos) return <LoadingView description={"loading"}/>

    const data = []
    for (const seriesElement of series.elements) {
        const audiobook = audiobooks.find(a => a.id == seriesElement.audiobookId)
        if (!audiobook) continue
        data.push({
            "seriesElement": seriesElement,
            "audiobook": audiobook,
            "userInfo": audiobookUserInfos.find(aui => aui.audiobookId == audiobook.id),
        })
    }
    let dataSorted = [...data].sort((b, a) => a.seriesElement.position > b.seriesElement.position ? -1 : 1)


    const totalSeriesDuration = data.map((ele) => ele.audiobook.lengthMsec).reduce((a, b) => a + b, 0)
    const booksFinishedCount = data.filter(ele => {
        return ele.userInfo && ele.audiobook && isFinished(ele.audiobook, ele.userInfo)
    }).length
    const progress = Math.round(booksFinishedCount / data.length * 100 * 100) / 100

    return (
        <div className="max-w-5xl mx-auto m-3">
            <div className="flex justify-center">
                <h1 className="text-3xl mb-5">{series.name}</h1>
            </div>

            <div className="sm:grid sm:grid-cols-3">
                <div className={`${cardCls} flex flex-col p-6 m-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesTotalDuration")}</div>
                    <div className="order-1 text-3xl font-bold">
                        {formatDuration(totalSeriesDuration)}
                    </div>
                </div>
                <div className={`${cardCls} flex flex-col p-6 m-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesTitleCount")}</div>
                    <div className="order-1 text-3xl font-bold">{data.length}</div>
                </div>
                <div className={`${cardCls} flex flex-col p-6 m-3 text-center`}>
                    <div className="order-2 mt-2 text-lg ">{t("seriesProgress")}</div>
                    <div className="order-1 text-3xl font-bold">{progress}%</div>
                </div>

            </div>

            <div className="grid grid-cols-8">
                {dataSorted.map((ele) => {
                    return (<>
                        <div className="col-span-1 m-auto sm:text-3xl text-2xl">
                            <div className="m-0">
                                #{ele.seriesElement.position}
                            </div>
                        </div>
                        <div className="col-span-7">
                            <SeriesBookCard key={ele.audiobook.id} audiobook={ele.audiobook} info={ele.userInfo}/>
                        </div>
                    </>)
                })}
            </div>
        </div>
    )
}