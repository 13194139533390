
export type SeriesSearchOptions = {
    text: string
}

export type AudiobookSearchOptions = {
    text: string
    state: SearchState
    interested: SearchInterested
    language: SearchLanguage
    audioAvailability: SearchAudioAvailability
}

export enum SearchState {
    FINISHED,
    UNFINISHED,
    ANY,
}

export enum SearchInterested {
    IGNORED,
    INTERESTED,
    ANY
}

export enum SearchLanguage {
    DE,
    EN,
    ES,
    ANY
}

export enum SearchAudioAvailability {
    AVAILABLE,
    UNAVAILABLE,
    ANY
}

export const initialAudiobookSearchOptions: AudiobookSearchOptions = {
    text: "",
    state: SearchState.ANY,
    interested: SearchInterested.ANY,
    language: SearchLanguage.ANY,
    audioAvailability: SearchAudioAvailability.ANY,
}