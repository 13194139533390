import {configureStore} from '@reduxjs/toolkit'
import userReducer from './userSlice'
import notificationReducer from "./notificationSlice";
import languageReducer from "../i18n/languageSlice";
import audiobookSearchReducer from "./audiobookSearchSlice";
import seriesSearchReducer from "./seriesSearchSlice";
import {baseApi} from "../api/baseApi";

export const store = configureStore({
    reducer: {
        user: userReducer,
        notification: notificationReducer,
        language: languageReducer,
        audiobookSearch: audiobookSearchReducer,
        seriesSearch: seriesSearchReducer,
        [baseApi.reducerPath]: baseApi.reducer,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // todo enable this again, disabled because of thumbnails being blobs
    }).concat(baseApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
