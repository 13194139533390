import {
    Author,
    Language, Narrator,
    UpdateAudiobook,
    useDeleteAudiobooksByIdMutation,
    useLazyGetAudibleAudibleMetadataByAsinQuery,
    useLazyGetAudiobooksByIdQuery, useLazyGetAuthorsQuery, useLazyGetNarratorsQuery,
    usePostAudiobooksMutation, usePostAuthorsMutation, usePostNarratorsMutation,
    usePutAudiobooksByIdMutation,
} from "../../api/generatedApi";
import useTranslation, {ALL_LANGUAGES} from "../../i18n";
import {cardCls, inputCls} from "../../styles";
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {setErrorNotification, setSuccessNotification} from "../../store/notificationSlice";
import {useDispatch} from "react-redux";
import {LabelComponent} from "../../components/ui/LabelComponent";
import {ThumbnailUploadComponent} from "../../components/audiobook/ThumbnailUploadComponent";
import {InputComponent} from "../../components/ui/InputComponent";
import {AudiofileUploadComponent} from "../../components/audiobook/AudiofileUploadComponent";
import {ButtonComponent} from "../../components/ui/ButtonComponent";
import {Many2ManyComponent} from "../../components/ui/Many2ManyComponent";
import {MultiValue} from "react-select";

export function AudiobookEditor() {
    const t = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [fetchAudiobook, {data: audiobook, error: audiobookError}] = useLazyGetAudiobooksByIdQuery();
    const [updateAudiobook, {data: updateAudiobookData, error: updateAudiobookError}] = usePutAudiobooksByIdMutation();
    const [createAudiobook, {data: createAudiobookData, error: createAudiobookError}] = usePostAudiobooksMutation();
    const [deleteAudiobook, {
        data: deleteAudiobookData,
        error: deleteAudiobookError
    }] = useDeleteAudiobooksByIdMutation();
    const [fetchAudibleMetadata, {
        data: fetchAudibleMetadataData,
        error: fetchAudibleMetadataError
    }] = useLazyGetAudibleAudibleMetadataByAsinQuery();
    const [fetchAvailableAuthors, {
        data: fetchAvailableAuthorsData,
        error: fetchAvailableAuthorsError
    }] = useLazyGetAuthorsQuery();
    const [fetchAvailableNarrators, {
        data: fetchAvailableNarratorsData,
        error: fetchAvailableNarratorsError
    }] = useLazyGetNarratorsQuery();
    const [createAuthor, {data: createAuthorData, error: createAuthorError}] = usePostAuthorsMutation();
    const [createNarrator, {data: createNarratorData, error: createNarratorError}] = usePostNarratorsMutation();

    const {audiobookId} = useParams()

    const [audibleAsin, setAudibleAsin] = useState<string>(audiobook ? audiobook.audibleASIN : "")
    const [name, setName] = useState<string>(audiobook ? audiobook.name : "")
    const [authors, setAuthors] = useState<Author[]>(audiobook ? audiobook.authors : [])
    const [narrators, setNarrators] = useState<Narrator[]>(audiobook ? audiobook.narrators : [])
    const [lengthMSec, setLengthMSec] = useState<number>(audiobook ? audiobook.lengthMsec : 0)
    const [publicationDate, setPublicationDate] = useState<string>("")
    const [language, setLanguage] = useState<Language>(audiobook ? audiobook.language : "german")
    const [description, setDescription] = useState<string>(audiobook ? audiobook.description : "")
    const [fileName, setFileName] = useState<string>(audiobook ? audiobook.fileName : "")

    const [durationMinutes, setDurationMinutes] = useState<number>(0)
    const [durationSeconds, setDurationSeconds] = useState<number>(0)
    const [durationHours, setDurationHours] = useState<number>(0)

    const [loadingMetadata, setLoadingMetadata] = useState<boolean>(false);

    const [availableAuthors, setAvailableAuthors] = useState<Author[]>([]);
    const [availableNarrators, setAvailableNarrators] = useState<Narrator[]>([]);

    useEffect(() => {
        if (audiobookError !== undefined) {
            dispatch(setErrorNotification({text: "Fetch Audiobook Failed"}));
            return;
        }

        if (audiobook === undefined) return;
        setName(audiobook.name);
        setAuthors(audiobook.authors);
        setNarrators(audiobook.narrators);
        setDescription(audiobook.description);
        setLanguage(audiobook.language);
        setLengthMSec(audiobook.lengthMsec);
        setPublicationDate(audiobook.publicationDate);
        setAudibleAsin(audiobook.audibleASIN);
        setFileName(audiobook.fileName);
    }, [audiobook])

    useEffect(() => {
        if (audiobookId === undefined) return;
        fetchAudiobook({id: audiobookId});
    }, [audiobookId])

    useEffect(() => {
        fetchAvailableAuthors();
        fetchAvailableNarrators();
    }, []);

    useEffect(() => {
        if (fetchAvailableAuthorsError !== undefined) {
            dispatch(setErrorNotification({text: "Fetch Authors Failed"}));
            return;
        }
        if (fetchAvailableAuthorsData === undefined) return;
        setAvailableAuthors(fetchAvailableAuthorsData);
    }, [fetchAvailableAuthorsData, fetchAvailableAuthorsError]);

    useEffect(() => {
        if (fetchAvailableNarratorsError !== undefined) {
            dispatch(setErrorNotification({text: "Fetch Narrators Failed"}));
            return;
        }
        if (fetchAvailableNarratorsData === undefined) return;
        setAvailableNarrators(fetchAvailableNarratorsData);
    }, [fetchAvailableNarratorsData, fetchAvailableNarratorsError]);

    useEffect(() => {
        if (createAudiobookError !== undefined) {
            dispatch(setErrorNotification({text: "Saving Audiobook Failed"}));
            console.log(createAudiobookError);
            return;
        }
        if (createAudiobookData === undefined) return;
        navigate("/audiobooks");
    }, [createAudiobookData, createAudiobookError]);

    useEffect(() => {
        if (updateAudiobookError !== undefined) {
            dispatch(setErrorNotification({title: "Saving Info Failed"}));
            console.log(updateAudiobookError);
            return;
        }
        if (updateAudiobookData === undefined) return;
        dispatch(setSuccessNotification({text: t("saved")}));
    }, [updateAudiobookData, updateAudiobookError])

    useEffect(() => {
        if (deleteAudiobookError !== undefined) {
            dispatch(setErrorNotification({text: "Delete Audiobook Failed"}));
            console.log(deleteAudiobookError);
            return;
        }
        if (deleteAudiobookData === undefined) return;
        navigate("/audiobooks");
    }, [deleteAudiobookData, deleteAudiobookError]);

    useEffect(() => {
        // update lengthMsec
        const duration = durationHours * 60 * 60 * 1000 + durationMinutes * 60 * 1000 + durationSeconds * 1000;
        setLengthMSec(duration);
    }, [durationHours, durationMinutes, durationSeconds]);

    useEffect(() => {
        // update duration
        const hours = Math.floor(lengthMSec / (60 * 60 * 1000));
        const minutes = Math.floor((lengthMSec - hours * 60 * 60 * 1000) / (60 * 1000));
        const seconds = Math.floor((lengthMSec - hours * 60 * 60 * 1000 - minutes * 60 * 1000) / 1000);
        setDurationHours(hours);
        setDurationMinutes(minutes);
        setDurationSeconds(seconds);
    }, [lengthMSec]);

    useEffect(() => {
        if (createAuthorError !== undefined) {
            dispatch(setErrorNotification({text: "Creating Author Failed"}));
            console.log(createAuthorError);
            return;
        }
        if (createAuthorData === undefined) return;
        setAuthors([...authors, createAuthorData]);
        setAvailableAuthors([...availableAuthors, createAuthorData]);
    }, [createAuthorData, createAuthorError]);

    useEffect(() => {
        console.log(createNarratorData);
        if (createNarratorError !== undefined) {
            dispatch(setErrorNotification({text: "Creating Narrator Failed"}));
            console.log(createNarratorError);
            return;
        }
        if (createNarratorData === undefined) return;
        setNarrators([...narrators, createNarratorData]);
        setAvailableNarrators([...availableNarrators, createNarratorData]);
    }, [createNarratorData, createNarratorError]);

    useEffect(() => {
        if (fetchAudibleMetadataError !== undefined) {
            dispatch(setErrorNotification({text: "Metadata fetching failed"}));
            console.log(fetchAudibleMetadataError);
            setLoadingMetadata(false);
            return;
        }
        if (fetchAudibleMetadataData === undefined) return;
        const d = fetchAudibleMetadataData;
        if (d.name) setName(d.name);
        if (d.authors) {
            d.authors.forEach((a) => {
                if (!availableAuthors.find((aa) => aa.id === a.id)) {
                    setAvailableAuthors([...availableAuthors, a]);
                }
            });
            setAuthors(d.authors);
        }
        if (d.narrators) {
            d.narrators.forEach((n) => {
                if (!availableNarrators.find((nn) => nn.id === n.id)) {
                    setAvailableNarrators([...availableNarrators, n]);
                }
            });
            setNarrators(d.narrators);
        }
        if (d.description) setDescription(d.description);
        if (d.lengthMsec) setLengthMSec(d.lengthMsec);
        if (d.publicationDate) setPublicationDate(d.publicationDate);
        if (d.language) setLanguage(d.language);
        dispatch(setSuccessNotification({text: "Metadata fetched"}));
        setLoadingMetadata(false);
    }, [fetchAudibleMetadataData, fetchAudibleMetadataError, loadingMetadata]);

    function saveAudiobook(e: { preventDefault: () => void }) {
        e.preventDefault();
        const audiobookData: UpdateAudiobook = {
            name: name,
            authors: authors,
            narrators: narrators,
            description: description,
            lengthMsec: lengthMSec,
            language: language,
            audibleASIN: audibleAsin,
            publicationDate: publicationDate,
        };
        if (audiobook === undefined) {
            createAudiobook({updateAudiobook: audiobookData});
        } else {
            updateAudiobook({id: audiobook.id, updateAudiobook: audiobookData});
        }
    }

    function authorIdsToAuthors(ids: string[]): Author[] {
        return availableAuthors.filter((a) => ids.includes(a.id));
    }

    function narratorIdsToNarrators(ids: string[]): Narrator[] {
        return availableNarrators.filter((n) => ids.includes(n.id));
    }

    function fetchMetadata(e: { preventDefault: () => void }) {
        e.preventDefault();
        if (audibleAsin === undefined || audibleAsin === "") {
            dispatch(setErrorNotification({title: "Fetch Metadata Failed", text: "No Asin set"}));
            return;
        }
        fetchAudibleMetadata({asin: audibleAsin});
        setLoadingMetadata(true);
    }

    function deleteAudiobookHandler(e: { preventDefault: () => void }) {
        e.preventDefault();
        if (audiobook === undefined) return;
        deleteAudiobook({id: audiobook.id});
    }

    return <>
        <div>
            <div className="flex justify-center">
                <h1 className="text-2xl leading-tight m-3">{audiobook ? t("audiobookAudiobookEdit") : t("audiobookAdd")}</h1>
            </div>
            <div className={`${cardCls} container m-auto my-5 p-5 pt-3`}>
                {audiobookId && <p className="mb-3 hover:underline">
                    <Link to={`/audiobooks/${audiobookId}`}>&lt; {t("back")}</Link>
                </p>}

                <form onSubmit={(e) => saveAudiobook(e)}>
                    <div className="mb-10 flex flex-nowrap">
                        <div>
                            <InputComponent value={audiobook ? audiobook.id : "-"} id="id-input" label="ID"
                                            disabled={true}/>
                        </div>
                        <div className="ml-2">
                            <InputComponent value={audibleAsin} id="asin-input" label="ASIN"
                                            onChange={(value) => setAudibleAsin(value)}/>
                        </div>
                    </div>
                    <div>
                        <InputComponent value={name} id="name-input" label={t("audiobookName")}
                                        onChange={(value) => setName(value)}/>
                    </div>
                    <div className="mt-3">
                        <Many2ManyComponent
                            values={authors.map((a) => ({value: a.id, label: a.name}))} id="auhors-selection"
                            options={availableAuthors.map((a) => ({value: a.id, label: a.name}))}
                            label={t("audiobookAuthor")}
                            onChange={(values) => {
                                const newAuthors = authorIdsToAuthors(values);
                                setAuthors(newAuthors);
                            }}
                            onCreate={(value) => {
                                createAuthor({body: {name: value}});
                            }}
                        />
                    </div>
                    <div className="mt-3">
                        <Many2ManyComponent
                            values={narrators.map((n) => ({value: n.id, label: n.name}))} id="narrators-selection"
                            options={availableNarrators.map((n) => ({value: n.id, label: n.name}))}
                            label={t("audiobookNarrator")}
                            onChange={(values) => {
                                const newNarrators = narratorIdsToNarrators(values);
                                setNarrators(newNarrators);
                            }}
                            onCreate={(value) => {
                                createNarrator({body: {name: value}});
                            }}
                        />
                    </div>
                    <div className="mt-3">
                        <div className="flex flex-row">
                            <div>
                                <InputComponent value={lengthMSec} id="lenght-msec-input" label={t("audiobookLengthMSec")}
                                                onChange={(value) => setLengthMSec(Number(value))}/>
                            </div>
                            <div className="ml-3">
                                <InputComponent value={durationHours} id="duration-hours-input"
                                                label={t("audiobookDurationHours")}
                                                className="w-16"
                                                onChange={(value) => setDurationHours(Number(value))}/>
                            </div>
                            <div className="ml-3">
                                <InputComponent value={durationMinutes} id="duration-minutes-input"
                                                label={t("audiobookDurationMinutes")}
                                                className="w-16"
                                                onChange={(value) => setDurationMinutes(Number(value))}/>
                            </div>
                            <div className="ml-3">
                                <InputComponent value={durationSeconds} id="duration-seconds-input"
                                                label={t("audiobookDurationSeconds")}
                                                className="w-16"
                                                onChange={(value) => setDurationSeconds(Number(value))}/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <InputComponent value={publicationDate} id="publication-input"
                                        label={t("audiobookPublicationDate")}
                                        onChange={(value) => setPublicationDate(value)} type="date"/>
                    </div>
                    <div className="mt-3">
                        <LabelComponent text={t("audiobookLanguage")} for="language-input"/>
                        <select
                            className={inputCls}
                            id="language-input"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value as Language)}
                        >
                            {ALL_LANGUAGES.map((l) => (
                                <option key={l} value={l}>
                                    {l}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mt-3">
                        <LabelComponent text={t("audiobookDescription")} for="description-input"/>
                        <textarea
                            className={`${inputCls} h-48`} id="description-input" value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className="my-3">
                        <InputComponent value={fileName} disabled={true} label="Filename" id="filename-input"/>
                    </div>

                    <ButtonComponent text={t("save")}
                                     onClick={(e) => saveAudiobook(e)}
                    />

                    <ButtonComponent text="Fetch and set metadata from audible" loading={loadingMetadata}
                                     onClick={(e) => fetchMetadata(e)}
                    />

                    <ButtonComponent text="Delete"
                                     onClick={(e) => deleteAudiobookHandler(e)}
                    />

                </form>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 container m-auto mb-5">
                {audiobook && <>
                    <div className="mb-5 lg:mb-0 lg:mr-5"><ThumbnailUploadComponent audiobook={audiobook}/></div>
                    <AudiofileUploadComponent audiobookId={audiobook.id}/>
                </>}
            </div>
        </div>
    </>
}