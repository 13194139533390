import {Translation} from "./index"

export type WishlistTranslationKey =
    | "wishlist"
    | "wishlistDescription"
    | "wishlistReleasedOn"
    | "wishlistAdd"
    | "wishlistWhatsMissing"
    | "wishlistReleaseDateInput"


export const WISHLIST_TRANSLATION: Translation<WishlistTranslationKey> = {
    english: {
        wishlist: "Wishlist",
        wishlistDescription: "Description",
        wishlistReleasedOn: "Released On",
        wishlistAdd: "Add to wishlist",
        wishlistWhatsMissing: "Which book is missing?",
        wishlistReleaseDateInput: "Release Date (optional)",
    },
    german: {
        wishlist: "Wunschliste",
        wishlistDescription: "Beschreibung",
        wishlistReleasedOn: "Erscheint am",
        wishlistAdd: "Zur Wunschliste hinzufügen",
        wishlistWhatsMissing: "Welches Buch fehlt?",
        wishlistReleaseDateInput: "Erscheinungsdatum (optional)",
    },
}
