import {Translation} from "./index"

export type ErrorCodesTranslationKey = "genericErrorMessage"
    | "error"
    | "backendNotReachable"

export const ERROR_CODE_TRANSLATION: Translation<ErrorCodesTranslationKey> = {
    english: {
        backendNotReachable: "Failed to connect to server",
        error: "Error",
        genericErrorMessage: "that did not work",
    },
    german: {
        backendNotReachable: "Der Server konnte nicht erreicht werden",
        error: "Fehler",
        genericErrorMessage: "Das hat nicht geklappt",
    }
}
