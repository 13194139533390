import {cardCls} from "../../styles"

import {useGetUsersQuery} from "../../api/generatedApi";
import {ErrorComponent} from "../../components/util/ErrorComponent";
import {LoadingView} from "../ErrorView";
import useTranslation from "../../i18n";
import {UserList} from "./Users";
import {CreateUserForm} from "./CreateUser";
import {Audible} from "./Audible";
import {Link} from "react-router-dom";
import React from "react";
import {ButtonComponent} from "../../components/ui/ButtonComponent";

export function AdminView() {
    const t = useTranslation()

    const {data: users, error, refetch} = useGetUsersQuery()
    if (error) {
        return <ErrorComponent error={error}/>
    }
    if (users === undefined) {
        return <LoadingView description={""}/>
    }

    return (
        <>
            <h1 className="flex justify-center m-3 text-2xl">
                {t("navBarAdmin")}
            </h1>

            <div id="adminActionBar" className="container m-auto" >
                <ButtonComponent text={<Link to="/audiobooks/add">{t("audiobookAdd")}</Link>}
                                 onClick={() => {}}/>
                <ButtonComponent text={<Link to="/series/add">{t("seriesAdd")}</Link>}
                                 onClick={() => {}}/>
            </div>

            <h2 className="flex justify-center p-3 text-xl">
                Users
            </h2>

            <div className={`${cardCls} container m-auto`}>
                <UserList users={users} refreshUsers={refetch}/>
            </div>

            <h2 className="flex justify-center p-3 text-xl">
                Create User
            </h2>

            <div className={`${cardCls} container m-auto p-5`}>
                <CreateUserForm successCallback={refetch}/>
            </div>

            <Audible/>
        </>
    )
}

