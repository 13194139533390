import {Resolution} from "../api/generatedApi";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectUser} from "../store/userSlice";
import ic_placeholder from "../img/placeholder.png";

export function getImageKey(audiobookId: string, resolution: Resolution) {
    return `z_${audiobookId}_${resolution.toString()}`
}

function storeImage(audiobookId: string, resolution: Resolution, blob: Blob) {
    const reader = new FileReader();
    reader.onload = (event) => {
        localStorage.setItem(getImageKey(audiobookId, resolution), <string>event.target!!.result!!)
    }
    reader.readAsDataURL(blob)
}

function loadImageFromCache(audiobookId: string, resolution: Resolution) {
    return localStorage.getItem(getImageKey(audiobookId, resolution))
}

export function useImageHook(audiobookId: string | undefined, resolution: Resolution) {
    const [imageSrc, setImgSrc] = useState<string>(ic_placeholder);
    const loggedInUser = useSelector(selectUser)

    useEffect(() => {
        if (!loggedInUser) return
        if (!audiobookId) return
        const image = loadImageFromCache(audiobookId, resolution)

        if (image) {
            setImgSrc(image)
            return
        }

        const src = `${import.meta.env.VITE_API_URL}audiobooks/${audiobookId}/thumbnail/${resolution}`
        const options = {
            headers: {'Authorization': `Bearer ${loggedInUser.sessionToken}`}
        };

        fetch(src, options)
            .then(res => {
                if (res.status == 404) {
                    throw new Error(`Not found! (${res.url})`)
                }
                return res.blob()
            })
            .then(blob => {
                storeImage(audiobookId, resolution, blob)
                setImgSrc(URL.createObjectURL(blob))
            }).catch((err) => {
                console.error(err.message);
            }
        );
    }, [audiobookId, resolution])

    return imageSrc
}
