import {Translation} from "./index"

export type HomeViewTranslationKey = "yourStats"
    | "listenedFor"
    | "bookCountStarted"
    | "bookCountFinished"
    | "bookCountTotal"
    | "homeViewWelcomeText"
    | "jumpBackIn"
    | "yourAllTimeFavorite"
    | "startRandomBook"
    | "recentlyAdded"
    | "recentlyAddedAudiobook"
    | "recentlyAddedSeries"
    | "weeksAgo"
    | "weekAgo"

export const HOME_VIEW_TRANSLATION: Translation<HomeViewTranslationKey> = {
    english: {
        "yourAllTimeFavorite": "Your all-time favorite",
        "homeViewWelcomeText": "Audioport: manage, listen to and search through your audiobooks",
        "startRandomBook": "start a random book",
        "jumpBackIn": "jump right back in",
        "yourStats": "Your Stats",
        "listenedFor": "Listened for",
        "bookCountStarted": "started",
        "bookCountFinished": "finished Books",
        "bookCountTotal": "total",
        "recentlyAdded": "Recently added",
        "weeksAgo": "weeks ago",
        "weekAgo": "week ago",
        "recentlyAddedAudiobook": "Recently added audiobooks",
        "recentlyAddedSeries": "Recently added series"
    },
    german: {
        "yourAllTimeFavorite": "Dein Lieblingsbuch",
        "homeViewWelcomeText": "Audioport: Verwalte, höre und durchsuche deine Hörbücher",
        "startRandomBook": "Höre ein zufälliges Buch",
        "jumpBackIn": "Hör gleich weiter",
        "yourStats": "Deine Statistiken",
        "listenedFor": "Zugehört für",
        "bookCountStarted": "begonnen",
        "bookCountFinished": "beendete Bücher",
        "bookCountTotal": "insgesamt",
        "recentlyAdded": "Kürzlich hinzugefügt",
        "weeksAgo": "Wochen her",
        "weekAgo": "Woche her",
        "recentlyAddedAudiobook": "Neue Hörbücher",
        "recentlyAddedSeries": "Neue Serien"
    }
}
