import {Translation} from "./index"

export type AudiobookTranslationKey =
    | "audiobook"
    | "audiobooks"
    | "audiobookName"
    | "audiobookAuthor"
    | "audiobookNarrator"
    | "audiobookLanguage"
    | "audiobookLength"
    | "audiobookLengthMSec"
    | "audiobookPublicationDate"
    | "audiobookAddedOn"
    | "audiobookDescription"
    | "audiobookDownloadBtn"
    | "audiobookRefresh"
    | "audiobookMarkFinished"
    | "audiobookMarkUnfinished"
    | "audiobookMarkInterested"
    | "audiobookMarkIgnore"
    | "audiobookGetToken"
    | "audiobookOnAudible"
    | "audiobookAdd"
    | "audiobookAudiobookEdit"
    | "play"
    | "audiobookDurationSeconds"
    | "audiobookDurationMinutes"
    | "audiobookDurationHours"

export const AUDIOBOOK_TRANSLATION: Translation<AudiobookTranslationKey> = {
    english: {
        play: "play",
        audiobook: "Audiobook",
        audiobooks: "Audiobooks",
        audiobookName: "Name",
        audiobookAuthor: "Author",
        audiobookNarrator: "Narrator",
        audiobookLanguage: "Language",
        audiobookLength: "Length",
        audiobookLengthMSec: "Length (in ms)",
        audiobookPublicationDate: "Published On",
        audiobookAddedOn: "Added On",
        audiobookDescription: "Description",
        audiobookDownloadBtn: "Download",
        audiobookRefresh: "Refresh",
        audiobookMarkFinished: "Mark Finished",
        audiobookMarkUnfinished: "Mark Unfinished",
        audiobookMarkInterested: "Interested",
        audiobookMarkIgnore: "Ignore",
        audiobookGetToken: "Get Token",
        audiobookOnAudible: "Open on Audible",
        audiobookAdd: "Add Audiobook",
        audiobookAudiobookEdit: "Edit Audiobook",
        audiobookDurationSeconds: "Seconds",
        audiobookDurationMinutes: "Minutes",
        audiobookDurationHours: "Hours",
    },
    german: {
        play: "abspielen",
        audiobook: "Hörbuch",
        audiobooks: "Hörbücher",
        audiobookName: "Name",
        audiobookAuthor: "Autor",
        audiobookNarrator: "Erzähler",
        audiobookLanguage: "Sprache",
        audiobookLength: "Länge",
        audiobookLengthMSec: "Länge (in ms)",
        audiobookPublicationDate: "Veröffentlicht am",
        audiobookAddedOn: "Hinzugefügt am",
        audiobookDescription: "Beschreibung",
        audiobookDownloadBtn: "Herunterladen",
        audiobookRefresh: "Aktualisieren",
        audiobookMarkFinished: "Als beendet markieren",
        audiobookMarkUnfinished: "Als neu markieren",
        audiobookMarkInterested: "Interessiert",
        audiobookMarkIgnore: "Ignorieren",
        audiobookGetToken: "Download Token holen",
        audiobookOnAudible: "Audible",
        audiobookAdd: "Hörbuch hinzufügen",
        audiobookAudiobookEdit: "Hörbuch bearbeiten",
        audiobookDurationSeconds: "Sekunden",
        audiobookDurationMinutes: "Minuten",
        audiobookDurationHours: "Stunden",
    }
}
