import {useDeleteWishlistByIdMutation, useGetWishlistQuery, usePostWishlistMutation} from "../api/generatedApi";
import {LoadingView} from "../views/ErrorView";
import {ErrorComponent, getErrorMessage} from "./util/ErrorComponent";
import React, {useEffect, useState} from "react";
import useTranslation from "../i18n";
import {ButtonComponent} from "./ui/ButtonComponent";
import {setErrorNotification, setSuccessNotification} from "../store/notificationSlice";
import {useDispatch} from "react-redux";
import {inputCls} from "../styles";
import {FiTrash} from "react-icons/all";
import {TagComponent} from "./ui/TagComponent";
import {InputComponent} from "./ui/InputComponent";
import {formatDate} from "../utils/util";

function NewWishlistElementForm(props: { successCallback: () => void }) {
    const t = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [releaseDate, setReleaseDate] = useState<string>("");

    const [postWishlistElement, {data, error}] = usePostWishlistMutation()

    useEffect(() => {
        if (error) {
            dispatch(setErrorNotification({text: t(getErrorMessage(error))}));
        }
    }, [error])

    useEffect(() => {
        if (data) {
            dispatch(setSuccessNotification({text: t("saved")}));
        }
    }, [data])

    return <div className="mx-auto max-w-2xl px-2 lg:px-8">
        <h2 className="font-bold text-xl">{t("addEntry")}</h2>
        <label
            className="block text-sm font-bold mb-2"
            htmlFor="config">
            {t("wishlistDescription")}
        </label>
        <textarea
            className={inputCls}
            id="name-input"
            placeholder={t("wishlistWhatsMissing")}
            value={name}
            onChange={(event) => setName(event.target.value)}
        />
        <InputComponent value={releaseDate} id="releaseDate-input"
                        label={t("wishlistReleaseDateInput")}
                        onChange={(value) => setReleaseDate(value)}
                        type="date"/>

        <ButtonComponent text={t("wishlistAdd")}
                         onClick={() => {
                             releaseDate ?
                                 postWishlistElement({body: {name: name, releaseDate: releaseDate}})
                                 :
                                 postWishlistElement({body: {name: name}})
                         }}
                         className="ml-0 my-3"/>
    </div>
}

function WishlistElement(props: { id: string, name: string, createdBy: string, releaseDate: string | undefined }) {
    const {id, name, createdBy, releaseDate} = props;

    const t = useTranslation();

    const [deleteWishlistElement, {data: deleteData, error: deleteError}] = useDeleteWishlistByIdMutation();

    return (
        <div className="bg-white dark:bg-slate-700 rounded p-2 mx-1 my-3 shadow-lg hover:ring-gray-400 hover:ring-2 grid grid-cols-12">
            <div className="col-span-10">{name}</div>
            <div className="col-span-2 flex justify-end ">
                <ButtonComponent text={<FiTrash />} onClick={(e) => {
                    console.log(e)
                    deleteWishlistElement({id: id})
                }} className="m-0 mr-1"/>
            </div>

            <div className="col-span-12">
                <TagComponent name={createdBy}/>
                {/* todo: red if not released yet - green if already released*/}
                {releaseDate && <TagComponent name={`${t("wishlistReleasedOn")}: ${formatDate(new Date(releaseDate), false)}`}/>}
            </div>
        </div>
    )
}

export function WishlistComponent() {
    const t = useTranslation();
    const {data: wishlist, error, refetch} = useGetWishlistQuery()

    const [addToWishlistOpen, setAddToWishlistOpen] = useState(false);

    if (error) {
        console.log(error)
        return <ErrorComponent error={error}/>
    }
    if (wishlist === undefined) {
        return <LoadingView description={""}/>
    }

    return (
        <div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                        {t("wishlist")}
                    </h2>
                </div>
            </div>

            <div className={`container m-auto`}>
                {wishlist.map(it => (
                    <WishlistElement key={it.id} id={it.id} name={it.name} createdBy={it.createdBy} releaseDate={it.releaseDate}/>
                ))}
            </div>


            {addToWishlistOpen &&
                <NewWishlistElementForm successCallback={refetch}/>
            }

            <div className=" grid place-content-center my-3">
                <ButtonComponent text={addToWishlistOpen ? "-" : "+"}
                                 onClick={(e) => setAddToWishlistOpen(!addToWishlistOpen)}
                                 className="w-16"/>
            </div>

        </div>
    )
}