import {
    AudibleAccount,
    useDeleteAudibleAccountsByIdMutation,
    useGetAudibleAccountsQuery,
    useLazyGetAudibleAccountsByIdConnectQuery,
    usePostAudibleAccountsMutation
} from "../../api/generatedApi";
import React, {useEffect, useState} from "react";
import ic_check from "../../img/checkmark.png";
import ic_cross from "../../img/cross.png";
import {btnCancelCls, btnPrimCls, cardCls, inputCls} from "../../styles";
import {ErrorComponent, getErrorMessage} from "../../components/util/ErrorComponent";
import {LoadingView} from "../ErrorView";
import {setErrorNotification, setSuccessNotification} from "../../store/notificationSlice";
import {useDispatch} from "react-redux";
import {formatString} from "../../utils/util";

export function Audible() {

    const {data: audibleAccounts, error, refetch} = useGetAudibleAccountsQuery()
    if (error) {
        return <ErrorComponent error={error}/>
    }
    if (audibleAccounts === undefined) {
        return <LoadingView description={""}/>
    }

    return (
        <>
            <h2 className="flex justify-center p-3 text-xl">
                Audible Connector
            </h2>

            <div className={`${cardCls} container m-auto`}>
                <AudibleAccountList audibleAccounts={audibleAccounts} refreshAudibleAccounts={refetch}/>
            </div>

            <h2 className="flex justify-center p-3 text-xl">
                Add Audible Account
            </h2>

            <div className={`${cardCls} container m-auto mb-5 p-5`}>
                <AddAudibleAccount refreshAudibleAccounts={refetch}/>
            </div>
        </>
    )
}

function AddAudibleAccount(props: {
    refreshAudibleAccounts: () => void
}) {
    const dispatch = useDispatch()

    const [email, setEmail] = useState("");
    const [config, setConfig] = useState("");

    const [addAudibleAccount, {data, error}] = usePostAudibleAccountsMutation()

    useEffect(() => {
        if (error !== undefined) {
            dispatch(setErrorNotification({text: "Adding Account Failed"}));
            return;
        }

        if (data === undefined) {
            return;
        }
        props.refreshAudibleAccounts();
    }, [data, error])

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        addAudibleAccount({body: {email: email, config: config}})
    }

    return (
        <div className="">
            <form
                onSubmit={submitForm}
                className="grid grid-cols-4"
            >
                <div className="mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="email">
                        Username
                    </label>
                    <input
                        className={inputCls}
                        type="text"
                        placeholder={"Email"}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <div className="col-span-2 ml-4 mb-4">
                    <label
                        className="block text-sm font-bold mb-2"
                        htmlFor="config">
                        Config
                    </label>
                    <textarea
                        className={inputCls}
                        placeholder={"JSON Formatted config retrieved from 'audible quickstart'"}
                        value={config}
                        onChange={(event) => setConfig(event.target.value)}
                    />
                </div>
                <div className="ml-4">
                    <button
                        type="submit"
                        className={btnPrimCls}
                    >
                        Add Audible Account
                    </button>
                </div>
            </form>
        </div>
    )
}

function AudibleAccountRow(props: { audibleAccount: AudibleAccount; refreshAudibleAccounts: () => void }) {
    const dispatch = useDispatch()
    const {audibleAccount, refreshAudibleAccounts} = props;
    const [connectAudibleAccount, {data, error: audibleConnectError}] = useLazyGetAudibleAccountsByIdConnectQuery()
    const [deleteAudibleAccount, {data: deleteData, error: audibleDeleteError}] = useDeleteAudibleAccountsByIdMutation()

    useEffect(() => {
        if (audibleConnectError !== undefined) {
            dispatch(setErrorNotification({text: "Connecting Account Failed"}));
        }
        if (data !== undefined) {
            refreshAudibleAccounts();
            dispatch(setSuccessNotification({text: "Audible account connected. Config is valid"}));
        }
    }, [audibleConnectError, data])

    useEffect(() => {
        if (audibleDeleteError !== undefined) {
            dispatch(setErrorNotification({
                title: "Deleting Account Failed", text: getErrorMessage(audibleDeleteError)}));

        }
        if (deleteData !== undefined) {
            refreshAudibleAccounts();
            dispatch(setSuccessNotification({text: "Audible account deleted."}));
        }
    }, [audibleDeleteError, deleteData])

    let tableDataClasses = "p-3 border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-slate-600 text-sm text-center";
    return (
        <tr>
            <td className={tableDataClasses.replace("text-center", "text-left")}>{audibleAccount.name}</td>
            <td className={tableDataClasses}>
                {audibleAccount.connected ? <img src={ic_check} width="24px" className={"m-auto"} alt="Yes"/> :
                    <img src={ic_cross} width="24px" className={"m-auto"} alt="No"/>}</td>
            <td className={tableDataClasses}>{audibleAccount.bookCount}</td>
            <td className={tableDataClasses}>{audibleAccount.lastSync}</td>
            <td className={tableDataClasses}>{formatString(audibleAccount.config, 30)}</td>
            <td className={`${tableDataClasses} text-right`}>
                <button
                    className={`${btnPrimCls} mr-2`}
                    onClick={(event) => {
                        event.preventDefault()
                        connectAudibleAccount({id: audibleAccount.id})
                    }}>
                    Connect
                </button>
                <button
                    className={btnCancelCls}
                    onClick={(event) => {
                        event.preventDefault();
                        deleteAudibleAccount({id: audibleAccount.id})
                    }}
                >
                    Delete
                </button>
            </td>
        </tr>
    );
}

export const AudibleAccountList = (props: {
    audibleAccounts: AudibleAccount[] | null;
    refreshAudibleAccounts: () => void;
}) => {
    const {audibleAccounts, refreshAudibleAccounts} = props;
    if (audibleAccounts === null) {
        return <div>No audibleAccounts available</div>;
    }
    let tableHeaderClasses = "p-4 border-b-2 border-blue-200 dark:border-slate-800 bg-blue-100 dark:bg-slate-700 " +
        "text-left text-xs font-semibold text-gray-600 dark:text-gray-200 uppercase tracking-wider";

    return (
        <table className="w-full">
            <thead>
            <tr>
                <th className={tableHeaderClasses}>Name</th>
                <th className={tableHeaderClasses + " text-center"}>Connected</th>
                <th className={tableHeaderClasses + " text-center"}>Book Count</th>
                <th className={tableHeaderClasses + " text-center"}>Last Sync</th>
                <th className={tableHeaderClasses + " text-center"}>Config</th>
                <th className={tableHeaderClasses}/>
            </tr>
            </thead>
            <tbody>
            {audibleAccounts.map((audibleAccount) => (
                <AudibleAccountRow
                    key={audibleAccount.id}
                    audibleAccount={audibleAccount}
                    refreshAudibleAccounts={refreshAudibleAccounts}
                />
            ))}
            </tbody>
        </table>
    );
};