import {useDispatch} from "react-redux";
import {
    Audiobook,
    usePostAudiobooksByIdThumbnailAndResMutation
} from "../../api/generatedApi";
import {useLazyGetAudibleAudibleThumbnailByAsinQuery} from "../../api/enhancedApi";
import React, {useEffect, useState} from "react";
import {getImageKey, useImageHook} from "../../utils/imageHook";
import {setErrorNotification, setSuccessNotification} from "../../store/notificationSlice";
import {cardCls} from "../../styles";
import {LabelComponent} from "../ui/LabelComponent";
import {FileUploader} from "../ui/FileUploader";
import {ButtonComponent} from "../ui/ButtonComponent";
import {getErrorMessage} from "../util/ErrorComponent";

export function ThumbnailUploadComponent(props: {audiobook: Audiobook}) {
    const dispatch = useDispatch()

    const [fetchingThumbnail, setFetchingThumbnail] = useState(false);

    const [updateThumbnail, {data: updateThumbnailData, error: updateThumbnailError }] = usePostAudiobooksByIdThumbnailAndResMutation();
    const [fetchAudibleThumbnail, {data: fetchAudibleThumbnailData, error: fetchAudibleThumbnailError }] = useLazyGetAudibleAudibleThumbnailByAsinQuery();


    const [thumbnail, setThumbnail] = useState<string>()
    const [thumbnailBlob, setThumbnailBlob] = useState<Blob>()

    const imgSrc = useImageHook(props.audiobook.id, 128)
    useEffect(() => {
        setThumbnail(imgSrc)
    }, [imgSrc]);

    useEffect(() => {
        if (updateThumbnailError) {
            dispatch(setErrorNotification({text: getErrorMessage(updateThumbnailError)}));
            return;
        }
        if (updateThumbnailData === undefined) {
            return;
        }
        dispatch(setSuccessNotification({text: "Thumbnail updated"}));
    }, [updateThumbnailError, updateThumbnailData])

    useEffect(() => {
        setFetchingThumbnail(false);
        
        if (fetchAudibleThumbnailError) {
            dispatch(setErrorNotification({text: getErrorMessage(fetchAudibleThumbnailError)}));
            return;
        }
        if (fetchAudibleThumbnailData === undefined) {
            return;
        }
        dispatch(setSuccessNotification({
            title: "Thumbnail fetched", text: "Click 'Save Thumbnail' to save use it as the new thumbnail"}));
        setThumbnail(URL.createObjectURL(fetchAudibleThumbnailData));
        setThumbnailBlob(fetchAudibleThumbnailData);
    }, [fetchAudibleThumbnailError, fetchAudibleThumbnailData])

    function saveThumbnail() {
        if (thumbnailBlob === undefined) {
            setErrorNotification({text: "No thumbnail selected"});
            return;
        }

        updateThumbnail({id: props.audiobook.id, res: 128, body: thumbnailBlob});
        localStorage.removeItem(getImageKey(props.audiobook.id, 128));
        localStorage.removeItem(getImageKey(props.audiobook.id, 512));
    }
    

    return <div className={`${cardCls} p-5`}>
        <LabelComponent text="Thumbnail"/>
        <p>Best fitted are square images (e.g. a 128px by 128px)</p>
        <img src={thumbnail}
             alt="Cover"
             className="my-3 w-60 h-60"
        />

        <FileUploader
            onFileSelect={(file) => {
                setThumbnail(URL.createObjectURL(file));
                setThumbnailBlob(file);
            }}
            className="mb-2"
        />
        <ButtonComponent text="Save Thumbnail"
                         onClick={(e) => {
                             e.preventDefault();
                             saveThumbnail();
                         }}
                         className="ml-0"
                         />

        <ButtonComponent text="Fetch Thumbnail from Audible"
                         onClick={(e) => {
                             e.preventDefault();
                             fetchAudibleThumbnail({asin: props.audiobook.audibleASIN});
                             setFetchingThumbnail(true);

                         }}
                         loading={fetchingThumbnail}
        />
    </div>
}
