import React, {useRef} from 'react'


export function FileUploader(props: {
    onFileSelect: (file: any) => void,
    className?: string
}) {

    const fileInput = useRef<HTMLInputElement>(null)

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            props.onFileSelect(e.target.files[0])
        }
    }

    return (
        <div className={`file-uploader ${props.className}`}>
            <input type="file" onChange={(e) => handleFileInput(e)}
                   className="rounded  bg-gray-200 dark:bg-slate-600 transition
                              file:p-2 file:border-0 file:dark:text-white
                              file:bg-slate-300 file:dark:bg-slate-500
                              file:hover:bg-slate-400 file:dark:hover:bg-slate-600 "
            />
            <button onClick={ _ => fileInput.current && fileInput.current.click()} className="btn btn-primary"/>
        </div>
    )
}