import {Translation} from "./index"

export type NavBarTranslationKey =
    | "navBarLibrary"
    | "navBarSeries"
    | "navBarImport"
    | "navBarProfile"
    | "navBarAdmin"
    | "navBarLogin"
    | "navBarLogout"
    | "navBarHome"
    | "navBarStatistics"

export const NAV_BAR_TRANSLATION: Translation<NavBarTranslationKey> = {
    english: {
        navBarHome: "Home",
        navBarLibrary: "Library",
        navBarSeries: "Series",
        navBarImport: "Import",
        navBarProfile: "Profile",
        navBarAdmin: "Admin",
        navBarLogin: "Login",
        navBarLogout: "Logout",
        navBarStatistics: "Statistics",
    },
    german: {
        navBarHome: "Start",
        navBarLibrary: "Bibliothek",
        navBarSeries: "Buchreihen",
        navBarImport: "Importieren",
        navBarProfile: "Profil",
        navBarAdmin: "Admin",
        navBarLogin: "Einloggen",
        navBarLogout: "Ausloggen",
        navBarStatistics: "Statistiken",
    },
}
