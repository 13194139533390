import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {RootState} from "../store/store";
import {UserWithToken} from "./generatedApi";

// initialize an empty api service that we'll inject endpoints into later as needed
console.log(`Connecting to backend at ${import.meta.env.VITE_API_URL}`)
export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
        prepareHeaders: (headers, {getState}) => {
            // If we have a token set in state, let's assume that we should be passing it.
            const user: UserWithToken | null = (getState() as RootState).user.value
            if (user) headers.append('authorization', `Bearer ${user.sessionToken}`)
            return headers
        }
    }),
    tagTypes: ['AudiobooksUserInfo'],
    endpoints: () => ({}),
})
