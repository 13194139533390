
import {Translation} from "./index"

export type ProfileTranslationKey =
    | "profile"
    | "profileLanguage"
    | "profileLanguageUpdateError"
    | "profileUsername"
    | "profilePassword"
    | "profileConfirmPassword"
    | "profileSaveChanges"
    | "updateUserNotification"

export const PROFILE_TRANSLATION: Translation<ProfileTranslationKey> = {
    english: {
        profileLanguageUpdateError: "Error while updating your language",
        profile: "Profile",
        profileLanguage: "Language",
        profileUsername: "Username",
        profilePassword: "Password",
        profileConfirmPassword: "Confirm Password",
        profileSaveChanges: "Save Changes",
        updateUserNotification: "Updated User Successfully"
    },
    german: {
        profileLanguageUpdateError: "Fehler beim Aktualisieren deiner Sprache",
        profile: "Profil",
        profileLanguage: "Sprache",
        profileUsername: "Benutzername",
        profilePassword: "Passwort",
        profileConfirmPassword: "Passwort bestätigen",
        profileSaveChanges: "Änderungen Speichern",
        updateUserNotification: "Nutzer erfolgreich aktualisiert",
    },
}
