import {Translation} from "./index"

export type SearchTranslationKey =
    | "searchAny"
    | "searchIgnore"
    | "searchInterested"
    | "searchNew"
    | "searchFinished"
    | "searchAudioAvailable"
    | "searchAudioUnavailable"

export const SEARCH_TRANSLATION: Translation<SearchTranslationKey> = {
    english: {
        searchAny: "Any",
        searchIgnore: "Ignore",
        searchInterested: "Interested",
        searchNew: "New",
        searchFinished: "Finished",
        searchAudioAvailable: "Audio available",
        searchAudioUnavailable: "Audio unavailable"
    },
    german: {
        searchAny: "Alle",
        searchIgnore: "Ignoriert",
        searchInterested: "Interessant",
        searchNew: "Neu",
        searchFinished: "Beendet",
        searchAudioAvailable: "Audio verfügbar",
        searchAudioUnavailable: "Audio nicht verfügbar"
    },
}
