import {Translation} from "./index"

export type StatisticsTranslationKey =
    | "statisticsBookCountByAuthor"
    | "statisticsBookLengthByAuthor"
    | "statisticsHours"
    | "statisticsCount"
    | "statisticsTotalSeriesLength"

export const STATISTICS_TRANSLATION: Translation<StatisticsTranslationKey> = {
    english: {
        statisticsBookCountByAuthor: "Book Count by Author",
        statisticsBookLengthByAuthor: "Book Duration by Author",
        statisticsHours: "Hours",
        statisticsCount: "Count",
        statisticsTotalSeriesLength: "Series Length",
    },
    german: {
        statisticsBookCountByAuthor: "Anzahl Bücher nach Autor",
        statisticsBookLengthByAuthor: "Gesamtdauer nach Autor",
        statisticsHours: "Stunden",
        statisticsCount: "Anzahl",
        statisticsTotalSeriesLength: "Gesamtdauer Serie",
    },
}


