import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {setLoggedInUser} from "../../store/userSlice";
import {useEffect} from "react";
import {ErrorMessage} from "../../api/generatedApi";
import {TranslationKey} from "../../i18n";
import useTranslation from "../../i18n";

function isMessageError(obj: any): boolean {
    return obj.message !== undefined && obj.id !== undefined
}

export function getErrorMessage(error: FetchBaseQueryError | SerializedError): TranslationKey {
    if ('data' in error && isMessageError(error.data)) {
        const oapiError = (error.data as ErrorMessage)
        console.error(JSON.stringify(oapiError))
    }

    if ('status' in error && error.status === "FETCH_ERROR") {
        console.error("backend is not reachable")
        return "backendNotReachable"
    }

    return "genericErrorMessage"

}

export function ErrorComponent(props: { error: FetchBaseQueryError | SerializedError }) {
    const dispatch = useDispatch()
    const t = useTranslation()

    useEffect(() => {
        if (props.error.hasOwnProperty("status")) {
            // user is not valid
            if ((props.error as FetchBaseQueryError).status === 401) {
                dispatch(setLoggedInUser(null))
            }
        }
    }, [props.error])

    return <div className="bg-white dark:bg-gray-800 px-4 py-16 h-full w-full sm:px-6 sm:py-24 flex justify-center items-center lg:px-8">
        <div className="max-w-max mx-auto">
            <main className="sm:flex">
                <p className="text-4xl font-extrabold sm:text-5xl sm:border-r sm:pr-6 sm:border-gray-200">{t("error")}</p>
                <div className="text-base text-gray-500 flex-grow flex justify-center items-center pl-6">
                    <div>{t(getErrorMessage(props.error))}</div>
                </div>
            </main>
        </div>
    </div>
}
