import {SeriesSearchOptions} from "../SearchTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {initialAudiobookSearchOptions} from "../SearchTypes";

const initialState: SeriesSearchOptions = initialAudiobookSearchOptions;

export const seriesSearchSlice = createSlice({
    name: 'seriesSearch',
    initialState,
    reducers: {
        setSeriesSearch: (state, action: PayloadAction<SeriesSearchOptions>) => {
            state.text = action.payload.text;
        },
    }
});

export const { setSeriesSearch } = seriesSearchSlice.actions;

export const selectSeriesSearch = (state: RootState) => state.seriesSearch;

export default seriesSearchSlice.reducer;