import {Link, useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {cardCls} from "../../styles"
import {formatDate, formatDuration} from "../../utils/util"
import {LoadingView} from "../ErrorView"
import {
    Series,
    useLazyGetAudiobooksByIdQuery, useLazyGetAudiobooksByIdSeriesQuery,
    useLazyGetAudiobooksByIdUserInfoQuery,
    usePutAudiobooksByIdUserInfoMutation
} from "../../api/generatedApi";
import {ErrorComponent} from "../../components/util/ErrorComponent";
import {isFinished} from "../../utils/audiobookUtils";
import {useImageHook} from "../../utils/imageHook";
import useTranslation, {TranslationKey} from "../../i18n";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/userSlice";
import {ButtonComponent} from "../../components/ui/ButtonComponent";
import {LinkComponent} from "../../components/ui/LinkComponent";

export function AudiobookDetailsView() {
    const t = useTranslation()
    const loggedInUser = useSelector(selectUser)!!

    const {audiobookId} = useParams()

    const [fetchAudiobook, {data: audiobook, error: audiobookError}] = useLazyGetAudiobooksByIdQuery()
    const [fetchInfo, {data: userInfo}] = useLazyGetAudiobooksByIdUserInfoQuery()
    const [fetchSeries, {data: seriesList, error: seriesError}] = useLazyGetAudiobooksByIdSeriesQuery()
    const [updateInfo, {data: updateData, error: updateError}] = usePutAudiobooksByIdUserInfoMutation()

    const [downloadUrl, setDownloadUrl] = useState("")

    useEffect(() => {
        if (updateError) {
            console.log(`Failed to update ${JSON.stringify(updateError)}`)
            return
        }
        if (updateData !== undefined && audiobookId !== undefined) {
            fetchInfo({id: audiobookId})
        }
    }, [updateError, updateData])

    // validate audiobookId and trigger fetching
    useEffect(() => {
        if (audiobookId === undefined) return

        fetchAudiobook({id: audiobookId})
        fetchInfo({id: audiobookId})
        fetchSeries({id: audiobookId})
    }, [audiobookId])

    const imgSrc = useImageHook(audiobookId, 512)

    if (audiobookError) return <ErrorComponent error={audiobookError}/>
    if (seriesError) return <ErrorComponent error={seriesError}/>
    if (!audiobook || !seriesList) return <LoadingView description={"loading"}/>

    const bookAppearsInSeries = function (series: Series) {
        return series.elements.some((element) => element.audiobookId === audiobook.id)
    }
    const seriesBookIsPartOf = seriesList.filter((series) => bookAppearsInSeries(series))

    const downloadByToken = () => {
        const fetchTokenUrl = `${import.meta.env.VITE_API_URL}audiobooks/${audiobookId}/download-token`
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${loggedInUser.sessionToken}`);

        fetch(fetchTokenUrl, {headers})
            .then(response => {
                if (response.ok) return response.json()
                throw new Error("failed to retrieve download token")
            })
            .then(token => {
                const downloadUrl = `${import.meta.env.VITE_API_URL}download/${token["Token"]}`
                setDownloadUrl(downloadUrl)
            })
    }

    const audibleLink = `https://www.audible.de/pd/${audiobook.audibleASIN}`

    return (
        <>
            <div className={`${cardCls} container m-auto my-5 p-5`}>
                <h2 className="text-3xl leading-tight mb-5">{audiobook.name}</h2>
                <div className="flex flex-wrap md:flex-nowrap justify-center md:justify-start mb-4">
                    <img src={imgSrc}
                         alt="Cover"
                         className="mb-3 w-60 h-60"
                    />

                    <div className="ml-7">
                        <div>
                            {seriesBookIsPartOf.map((series) =>
                                <Link to={`/series/${series.id}`}
                                      className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block"
                                      key={series.id}
                                >
                                    <span
                                        className="font-bold mr-1">{series.elements.find(it => it.audiobookId === audiobook.id)?.position}# </span>
                                    {series.name}
                                </Link>
                            )}
                        </div>

                        <div className="mt-2 flex flex-wrap">
                            <div className="mr-4">
                                <label htmlFor="author" className="block text-xs">
                                    {t("audiobookAuthor")}
                                </label>
                                {
                                    audiobook.authors.map((author) =>
                                        <span
                                            id="author"
                                            key={author.id}
                                            className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block mr-1 my-1">
                                            {author.name}
                                        </span>
                                    )
                                }
                            </div>

                            <div className="mr-4">
                                <label htmlFor="narrator" className="block text-xs">
                                    {t("audiobookNarrator")}
                                </label>
                                {
                                    audiobook.narrators.map((narrator) =>
                                        <span
                                            id="narrator"
                                            key={narrator.id}
                                            className="text-xs uppercase px-3 py-1 rounded-full bg-gray-200 text-gray-700 max-w-xs inline-block mr-1 my-1">

                                            {narrator.name}
                                        </span>
                                    )
                                }
                            </div>

                            <div className="mr-4">
                                <label htmlFor="language" className="block text-xs">
                                    {t("audiobookLanguage")}
                                </label>
                                <span id="language" className="block w-full">
                                    {t(audiobook.language as TranslationKey)}
                                </span>
                            </div>

                            <div className="mr-4">
                                <label htmlFor="duration" className="block text-xs">
                                    {t("audiobookLength")}
                                </label>
                                <span id="duration" className="block w-full">
                                    {formatDuration(audiobook.lengthMsec)}
                                </span>
                            </div>
                            <div className="mr-4">
                                <label htmlFor="publication" className="block text-xs">
                                    {t("audiobookPublicationDate")}
                                </label>
                                <span id="publication" className="block w-full">
                                    {formatDate(new Date(Date.parse(audiobook.publicationDate)), false)}
                                </span>
                            </div>
                            <div>
                                <label htmlFor="added-on" className="block text-xs">
                                    {t("audiobookAddedOn")}
                                </label>
                                <span id="added-on" className="block w-full">
                                    {formatDate(new Date(Date.parse(audiobook.timeCreated)), false)}
                                </span>
                            </div>
                        </div>

                        <div className="mt-2">
                            <label htmlFor="description" className="block text-xs">
                                {t("audiobookDescription")}
                            </label>
                            {audiobook.description}
                        </div>

                    </div>
                </div>
                <div className="flex flex-wrap">
                    {!downloadUrl ?
                        <ButtonComponent text={t("audiobookGetToken")} onClick={downloadByToken}
                                         disabled={!audiobook.audioAvailable}/> :
                        <LinkComponent text={t("audiobookDownloadBtn")} href={downloadUrl}/>
                    }

                    <ButtonComponent
                        text={userInfo !== undefined && isFinished(audiobook, userInfo) ? t("audiobookMarkUnfinished") : t("audiobookMarkFinished")}
                        onClick={() => {
                            if (userInfo !== undefined && isFinished(audiobook, userInfo)) {
                                updateInfo({id: audiobook.id, updateAudiobookUserInfo: {progressMSec: 0}})
                            } else {
                                updateInfo({
                                    id: audiobook.id,
                                    updateAudiobookUserInfo: {progressMSec: audiobook.lengthMsec}
                                })
                            }
                        }}
                    />

                    <ButtonComponent
                        text={userInfo === undefined || userInfo.isInterested ? t("audiobookMarkIgnore") : t("audiobookMarkInterested")}
                        onClick={() => {
                            updateInfo({
                                id: audiobook.id,
                                updateAudiobookUserInfo: {
                                    isInterested: userInfo !== undefined && !userInfo.isInterested
                                }
                            })
                        }}
                    />

                    {audiobook.audibleASIN &&
                        <LinkComponent className="ml-2" text={t("audiobookOnAudible")}
                                       href={audibleLink} newTab={true}/>
                    }

                    {loggedInUser.isAdmin &&
                        <ButtonComponent text={<Link to={`/audiobooks/${audiobook.id}/edit`}>{t("edit")}</Link>}
                                         onClick={() => {
                                         }}/>
                    }
                </div>
            </div>
        </>
    )
}
