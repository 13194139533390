import React, {useState} from "react";
import {btnCancelCls, btnPrimCls, inputCls} from "../../styles";
import {useDeleteUsersByIdMutation, User} from "../../api/generatedApi";
import ic_check from "../../img/checkmark.png";
import ic_cross from "../../img/cross.png";
import {ErrorComponent} from "../../components/util/ErrorComponent";

function UserDeleteModal(props: {
    visible: boolean;
    close: (refresh: boolean) => void;
    user: User;
}) {
    const {visible, user, close} = props;
    const [userName, setUserName] = useState<string>("");

    const [deleteUser, {error}] = useDeleteUsersByIdMutation()
    if (error) {
        return <ErrorComponent error={error}/>
    }

    if (!visible) {
        return null;
    }

    const canDelete = userName === user.name;
    return (
        <div className="absolute top-0 left-0 bg-opacity-40 bg-gray-100 z-[2] w-full h-full">
            <div
                className="fixed rounded shadow p-5 bg-white dark:bg-slate-700 grid"
                style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    gridTemplateRows: "repeat(3, auto-fit)",
                    gridTemplateColumns: "repeat(2, 1fr)",
                }}
            >
                <span className="col-span-2 text-left text-base">
                    This will delete the user <b>{user.name}</b> and all of their data. If
                    you are sure you want to do this, then type the username into the
                    field below and press "Delete".
                </span>
                <input
                    className={`${inputCls} my-3 col-span-2`}
                    value={userName}
                    onChange={(event) => {
                        event.preventDefault();
                        setUserName(event.target.value);
                    }}
                />
                <button
                    className={btnPrimCls}
                    onClick={(event) => {
                        event.preventDefault();
                        close(false);
                    }}
                >
                    Cancel
                </button>
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        deleteUser({id: user.id});
                    }}
                    disabled={!canDelete}
                    className={`${btnCancelCls} ml-3 ${canDelete ? "" : "hover:cursor-not-allowed hover:bg-red-500 dark:hover:bg-red-600"
                    }`}
                >
                    Delete
                </button>

            </div>
        </div>
    );
}

function UserRow(props: { user: User; refreshUsers: () => void }) {
    const {user, refreshUsers} = props;
    const [deleteVisible, setDeleteVisible] = useState(false);

    let tableDataClasses = "p-3 border-b border-gray-200 dark:border-gray-500 bg-white dark:bg-slate-600 text-sm text-center";
    return (
        <tr>
            <td className={tableDataClasses.replace("text-center", "text-left")}>{user.name}</td>
            <td className={tableDataClasses}>
                {user.isAdmin ? <img src={ic_check} width="24px" className={"m-auto"} alt="Yes"/> :
                    <img src={ic_cross} width="24px" className={"m-auto"} alt="No"/>}</td>
            <td className={tableDataClasses}>{user.language}</td>
            <td className={tableDataClasses}>{user.id}</td>
            <td className={tableDataClasses + " text-right"}>
                <button
                    className={btnCancelCls}
                    onClick={(event) => {
                        event.preventDefault();
                        setDeleteVisible(true);
                    }}
                >
                    Delete
                </button>
                <UserDeleteModal
                    visible={deleteVisible}
                    close={(refresh) => {
                        setDeleteVisible(false);
                        if (refresh) {
                            refreshUsers();
                        }
                    }}
                    user={user}
                />
            </td>
        </tr>
    );
}

export const UserList = (props: {
    users: User[] | null;
    refreshUsers: () => void;
}) => {
    const {users, refreshUsers} = props;
    if (users === null) {
        return <div>No users available</div>;
    }
    let tableHeaderClasses = "p-4 border-b-2 border-blue-200 dark:border-slate-800 bg-blue-100 dark:bg-slate-700 " +
        "text-left text-xs font-semibold text-gray-600 dark:text-gray-200 uppercase tracking-wider";

    return (
        <table className="w-full">
            <thead>
            <tr>
                <th className={tableHeaderClasses}>Name</th>
                <th className={tableHeaderClasses + " text-center"}>Is Admin</th>
                <th className={tableHeaderClasses + " text-center"}>Language</th>
                <th className={tableHeaderClasses + " text-center"}>ID</th>
                <th className={tableHeaderClasses}/>
            </tr>
            </thead>
            <tbody>
            {users.map((user) => (
                <UserRow
                    key={user.id}
                    user={user}
                    refreshUsers={refreshUsers}
                />
            ))}
            </tbody>
        </table>
    );
};
