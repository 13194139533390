import {AudiobookSearchOptions} from "../SearchTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {initialAudiobookSearchOptions} from "../SearchTypes";

const initialState: AudiobookSearchOptions = initialAudiobookSearchOptions;

export const audiobookSearchSlice = createSlice({
    name: 'audiobookSearch',
    initialState,
    reducers: {
        setAudiobookSearch: (state, action: PayloadAction<AudiobookSearchOptions>) => {
            state.text = action.payload.text;
            state.audioAvailability = action.payload.audioAvailability;
            state.language = action.payload.language;
            state.state = action.payload.state;
            state.interested = action.payload.interested;
        },
    }
});

export const { setAudiobookSearch } = audiobookSearchSlice.actions;

export const selectAudiobookSearch = (state: RootState) => state.audiobookSearch;

export default audiobookSearchSlice.reducer;