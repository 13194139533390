import {Translation} from "./index"

export type LoginTranslationKey =
    | "loginUsername"
    | "loginPassword"
    | "loginSignInBtn"
    | "loginErrorTitle"
    | "loginLoading"
    | "loginWelcomeTitle"
    | "loginWelcomeText"

export const LOGIN_TRANSLATION: Translation<LoginTranslationKey> = {
    english: {
        loginUsername: "Username",
        loginPassword: "Password",
        loginSignInBtn: "Sign In",
        loginErrorTitle: "Failed to login",
        loginLoading: "logging you in...",
        loginWelcomeTitle: "welcome",
        loginWelcomeText: "you are now logged in"
    },
    german: {
        loginUsername: "Benutzername",
        loginPassword: "Passwort",
        loginSignInBtn: "Einloggen",
        loginErrorTitle: "Fehler beim Anmelden",
        loginLoading: "du wirst angemeldet...",
        loginWelcomeTitle: "Willkommen",
        loginWelcomeText: "Viel Spaß beim hören"
    },
}
