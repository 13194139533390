import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../store/store";
import {Language} from "./index";


interface NotificationState {
    currentLanguage: Language
}

const initialState: NotificationState = {
    currentLanguage: "english"
}


export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<Language>) => {
            state.currentLanguage = action.payload
        },
    }
})

export const {setLanguage} = languageSlice.actions

export const currentLanguage = (state: RootState) => state.language.currentLanguage

export default languageSlice.reducer
