import { baseApi as api } from "./baseApi";
export const addTagTypes = [
  "userinfo",
  "audiobook",
  "series",
  "wishlist",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getPing: build.query<GetPingApiResponse, GetPingApiArg>({
        query: () => ({ url: `/ping` }),
      }),
      getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
        query: () => ({ url: `/version` }),
      }),
      postLogin: build.mutation<PostLoginApiResponse, PostLoginApiArg>({
        query: (queryArg) => ({
          url: `/login`,
          method: "POST",
          body: queryArg.loginCredentials,
        }),
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: () => ({ url: `/users` }),
      }),
      postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
        query: (queryArg) => ({
          url: `/users`,
          method: "POST",
          body: queryArg.createUser,
        }),
      }),
      getUsersById: build.query<GetUsersByIdApiResponse, GetUsersByIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
      }),
      postUsersById: build.mutation<
        PostUsersByIdApiResponse,
        PostUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "POST",
          body: queryArg.updateUser,
        }),
      }),
      deleteUsersById: build.mutation<
        DeleteUsersByIdApiResponse,
        DeleteUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "DELETE",
        }),
      }),
      getUsersByIdUserInfo: build.query<
        GetUsersByIdUserInfoApiResponse,
        GetUsersByIdUserInfoApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg.id}/user-info` }),
        providesTags: ["userinfo"],
      }),
      getAuthors: build.query<GetAuthorsApiResponse, GetAuthorsApiArg>({
        query: () => ({ url: `/authors` }),
      }),
      postAuthors: build.mutation<PostAuthorsApiResponse, PostAuthorsApiArg>({
        query: (queryArg) => ({
          url: `/authors`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getNarrators: build.query<GetNarratorsApiResponse, GetNarratorsApiArg>({
        query: () => ({ url: `/narrators` }),
      }),
      postNarrators: build.mutation<
        PostNarratorsApiResponse,
        PostNarratorsApiArg
      >({
        query: (queryArg) => ({
          url: `/narrators`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getAudiobooks: build.query<GetAudiobooksApiResponse, GetAudiobooksApiArg>(
        {
          query: () => ({ url: `/audiobooks` }),
          providesTags: ["audiobook"],
        }
      ),
      postAudiobooks: build.mutation<
        PostAudiobooksApiResponse,
        PostAudiobooksApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks`,
          method: "POST",
          body: queryArg.updateAudiobook,
        }),
        invalidatesTags: ["audiobook"],
      }),
      getAudiobooksById: build.query<
        GetAudiobooksByIdApiResponse,
        GetAudiobooksByIdApiArg
      >({
        query: (queryArg) => ({ url: `/audiobooks/${queryArg.id}` }),
        providesTags: ["audiobook"],
      }),
      putAudiobooksById: build.mutation<
        PutAudiobooksByIdApiResponse,
        PutAudiobooksByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateAudiobook,
        }),
        invalidatesTags: ["audiobook"],
      }),
      deleteAudiobooksById: build.mutation<
        DeleteAudiobooksByIdApiResponse,
        DeleteAudiobooksByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["audiobook"],
      }),
      getAudiobooksByIdUserInfo: build.query<
        GetAudiobooksByIdUserInfoApiResponse,
        GetAudiobooksByIdUserInfoApiArg
      >({
        query: (queryArg) => ({ url: `/audiobooks/${queryArg.id}/user-info` }),
        providesTags: ["userinfo"],
      }),
      putAudiobooksByIdUserInfo: build.mutation<
        PutAudiobooksByIdUserInfoApiResponse,
        PutAudiobooksByIdUserInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}/user-info`,
          method: "PUT",
          body: queryArg.updateAudiobookUserInfo,
        }),
        invalidatesTags: ["userinfo"],
      }),
      getAudiobooksByIdSeries: build.query<
        GetAudiobooksByIdSeriesApiResponse,
        GetAudiobooksByIdSeriesApiArg
      >({
        query: (queryArg) => ({ url: `/audiobooks/${queryArg.id}/series` }),
        providesTags: ["series"],
      }),
      getDownloadByToken: build.query<
        GetDownloadByTokenApiResponse,
        GetDownloadByTokenApiArg
      >({
        query: (queryArg) => ({ url: `/download/${queryArg.token}` }),
      }),
      getAudiobooksByIdDownloadToken: build.query<
        GetAudiobooksByIdDownloadTokenApiResponse,
        GetAudiobooksByIdDownloadTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}/download-token`,
        }),
      }),
      postAudiobooksByIdAudiofile: build.mutation<
        PostAudiobooksByIdAudiofileApiResponse,
        PostAudiobooksByIdAudiofileApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}/audiofile`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getAudibleAudibleThumbnailByAsin: build.query<
        GetAudibleAudibleThumbnailByAsinApiResponse,
        GetAudibleAudibleThumbnailByAsinApiArg
      >({
        query: (queryArg) => ({
          url: `/audible/audible-thumbnail/${queryArg.asin}`,
        }),
      }),
      getAudibleAudibleMetadataByAsin: build.query<
        GetAudibleAudibleMetadataByAsinApiResponse,
        GetAudibleAudibleMetadataByAsinApiArg
      >({
        query: (queryArg) => ({
          url: `/audible/audible-metadata/${queryArg.asin}`,
        }),
      }),
      getAudiobooksByIdThumbnailAndRes: build.query<
        GetAudiobooksByIdThumbnailAndResApiResponse,
        GetAudiobooksByIdThumbnailAndResApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}/thumbnail/${queryArg.res}`,
        }),
      }),
      postAudiobooksByIdThumbnailAndRes: build.mutation<
        PostAudiobooksByIdThumbnailAndResApiResponse,
        PostAudiobooksByIdThumbnailAndResApiArg
      >({
        query: (queryArg) => ({
          url: `/audiobooks/${queryArg.id}/thumbnail/${queryArg.res}`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      getSeries: build.query<GetSeriesApiResponse, GetSeriesApiArg>({
        query: () => ({ url: `/series` }),
        providesTags: ["series"],
      }),
      postSeries: build.mutation<PostSeriesApiResponse, PostSeriesApiArg>({
        query: (queryArg) => ({
          url: `/series`,
          method: "POST",
          body: queryArg.updateSeries,
        }),
        invalidatesTags: ["series"],
      }),
      getSeriesById: build.query<GetSeriesByIdApiResponse, GetSeriesByIdApiArg>(
        {
          query: (queryArg) => ({ url: `/series/${queryArg.id}` }),
          providesTags: ["series"],
        }
      ),
      putSeriesById: build.mutation<
        PutSeriesByIdApiResponse,
        PutSeriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/series/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateSeries,
        }),
        invalidatesTags: ["series"],
      }),
      deleteSeriesById: build.mutation<
        DeleteSeriesByIdApiResponse,
        DeleteSeriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/series/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["series"],
      }),
      getAudibleAccounts: build.query<
        GetAudibleAccountsApiResponse,
        GetAudibleAccountsApiArg
      >({
        query: () => ({ url: `/audible-accounts` }),
      }),
      postAudibleAccounts: build.mutation<
        PostAudibleAccountsApiResponse,
        PostAudibleAccountsApiArg
      >({
        query: (queryArg) => ({
          url: `/audible-accounts`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
      deleteAudibleAccountsById: build.mutation<
        DeleteAudibleAccountsByIdApiResponse,
        DeleteAudibleAccountsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/audible-accounts/${queryArg.id}`,
          method: "DELETE",
        }),
      }),
      getAudibleAccountsByIdConnect: build.query<
        GetAudibleAccountsByIdConnectApiResponse,
        GetAudibleAccountsByIdConnectApiArg
      >({
        query: (queryArg) => ({
          url: `/audible-accounts/${queryArg.id}/connect`,
        }),
      }),
      getStatistics: build.query<GetStatisticsApiResponse, GetStatisticsApiArg>(
        {
          query: () => ({ url: `/statistics` }),
        }
      ),
      getWishlist: build.query<GetWishlistApiResponse, GetWishlistApiArg>({
        query: () => ({ url: `/wishlist` }),
        providesTags: ["wishlist"],
      }),
      postWishlist: build.mutation<PostWishlistApiResponse, PostWishlistApiArg>(
        {
          query: (queryArg) => ({
            url: `/wishlist`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["wishlist"],
        }
      ),
      deleteWishlistById: build.mutation<
        DeleteWishlistByIdApiResponse,
        DeleteWishlistByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/wishlist/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["wishlist"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as generatedApi };
export type GetPingApiResponse = unknown;
export type GetPingApiArg = void;
export type GetVersionApiResponse =
  /** status 200 information of the server version and build time */ Version;
export type GetVersionApiArg = void;
export type PostLoginApiResponse =
  /** status 200 Successful Login response */ UserWithToken;
export type PostLoginApiArg = {
  loginCredentials: LoginCredentials;
};
export type GetUsersApiResponse = /** status 200 Get all users */ User[];
export type GetUsersApiArg = void;
export type PostUsersApiResponse =
  /** status 200 Create a new user */ UserWithPassword;
export type PostUsersApiArg = {
  createUser: CreateUser;
};
export type GetUsersByIdApiResponse =
  /** status 200 Information about the current user */ User;
export type GetUsersByIdApiArg = {
  /** The id of the user */
  id: string;
};
export type PostUsersByIdApiResponse =
  /** status 204 Updated the current user */ undefined;
export type PostUsersByIdApiArg = {
  /** The id of the user */
  id: string;
  /** Information that should be set on the current user */
  updateUser: UpdateUser;
};
export type DeleteUsersByIdApiResponse =
  /** status 204 The user got deleted */ undefined;
export type DeleteUsersByIdApiArg = {
  /** The id of the user */
  id: string;
};
export type GetUsersByIdUserInfoApiResponse =
  /** status 200 Retrieve user specific meta information to the specified audiobook */ AudiobookUserInfo[];
export type GetUsersByIdUserInfoApiArg = {
  /** The id of the user */
  id: string;
};
export type GetAuthorsApiResponse = /** status 200 Get all authors */ Author[];
export type GetAuthorsApiArg = void;
export type PostAuthorsApiResponse =
  /** status 200 Successful Add Author response, returns the created author */ Author;
export type PostAuthorsApiArg = {
  body: {
    name: string;
  };
};
export type GetNarratorsApiResponse =
  /** status 200 Get all narrators */ Narrator[];
export type GetNarratorsApiArg = void;
export type PostNarratorsApiResponse =
  /** status 200 Successful Add Narrator response */ Narrator;
export type PostNarratorsApiArg = {
  body: {
    name: string;
  };
};
export type GetAudiobooksApiResponse =
  /** status 200 Available audiobooks */ Audiobook[];
export type GetAudiobooksApiArg = void;
export type PostAudiobooksApiResponse = unknown;
export type PostAudiobooksApiArg = {
  updateAudiobook: UpdateAudiobook;
};
export type GetAudiobooksByIdApiResponse =
  /** status 200 An Audiobook */ Audiobook;
export type GetAudiobooksByIdApiArg = {
  /** The id of the audiobook */
  id: string;
};
export type PutAudiobooksByIdApiResponse =
  /** status 204 Updating audiobook was successful */ undefined;
export type PutAudiobooksByIdApiArg = {
  /** The id of the audiobook */
  id: string;
  updateAudiobook: UpdateAudiobook;
};
export type DeleteAudiobooksByIdApiResponse =
  /** status 200 A success message */ undefined;
export type DeleteAudiobooksByIdApiArg = {
  /** The id of the audiobook */
  id: string;
};
export type GetAudiobooksByIdUserInfoApiResponse =
  /** status 200 Retrieve user specific meta information to the specified audiobook */ AudiobookUserInfo;
export type GetAudiobooksByIdUserInfoApiArg = {
  /** The id of the audiobook */
  id: string;
};
export type PutAudiobooksByIdUserInfoApiResponse =
  /** status 204 successfully updated user info */ undefined;
export type PutAudiobooksByIdUserInfoApiArg = {
  /** The id of the audiobook */
  id: string;
  /** new audiobooks */
  updateAudiobookUserInfo: UpdateAudiobookUserInfo;
};
export type GetAudiobooksByIdSeriesApiResponse =
  /** status 200 Retrieve series this audiobook is part of */ Series[];
export type GetAudiobooksByIdSeriesApiArg = {
  /** The id of the audiobook */
  id: string;
};
export type GetDownloadByTokenApiResponse = /** status 200 A resource */ Blob;
export type GetDownloadByTokenApiArg = {
  /** The access token for the requested resource */
  token: string;
};
export type GetAudiobooksByIdDownloadTokenApiResponse =
  /** status 200 the acess token */ DownloadToken;
export type GetAudiobooksByIdDownloadTokenApiArg = {
  /** The id of the audiobook */
  id: string;
};
export type PostAudiobooksByIdAudiofileApiResponse = unknown;
export type PostAudiobooksByIdAudiofileApiArg = {
  /** The id of the audiobook */
  id: string;
  body: Blob;
};
export type GetAudibleAudibleThumbnailByAsinApiResponse =
  /** status 200 the thumbnail */ Blob;
export type GetAudibleAudibleThumbnailByAsinApiArg = {
  /** The audible asin of the audiobook */
  asin: string;
};
export type GetAudibleAudibleMetadataByAsinApiResponse =
  /** status 200 metadata for this audiobook fetched from audible */ UpdateAudiobook;
export type GetAudibleAudibleMetadataByAsinApiArg = {
  /** The audible asin of the audiobook */
  asin: string;
};
export type GetAudiobooksByIdThumbnailAndResApiResponse =
  /** status 200 the thumbnail */ Blob;
export type GetAudiobooksByIdThumbnailAndResApiArg = {
  /** The id of the audiobook */
  id: string;
  /** resolution of the thumbnail */
  res?: Resolution;
};
export type PostAudiobooksByIdThumbnailAndResApiResponse = unknown;
export type PostAudiobooksByIdThumbnailAndResApiArg = {
  /** The id of the audiobook */
  id: string;
  /** resolution of the thumbnail */
  res?: Resolution;
  body: Blob;
};
export type GetSeriesApiResponse =
  /** status 200 Retrieve all audiobook series information with elements that are part of the series */ Series[];
export type GetSeriesApiArg = void;
export type PostSeriesApiResponse = unknown;
export type PostSeriesApiArg = {
  updateSeries: UpdateSeries;
};
export type GetSeriesByIdApiResponse = /** status 200  */ Series;
export type GetSeriesByIdApiArg = {
  /** The id of the series */
  id: string;
};
export type PutSeriesByIdApiResponse = unknown;
export type PutSeriesByIdApiArg = {
  /** The id of the series */
  id: string;
  updateSeries: UpdateSeries;
};
export type DeleteSeriesByIdApiResponse =
  /** status 204 The series got deleted */ undefined;
export type DeleteSeriesByIdApiArg = {
  /** The id of the series */
  id: string;
};
export type GetAudibleAccountsApiResponse =
  /** status 200 audible accounts */ AudibleAccount[];
export type GetAudibleAccountsApiArg = void;
export type PostAudibleAccountsApiResponse = unknown;
export type PostAudibleAccountsApiArg = {
  body: {
    id?: string;
    email: string;
    config: string;
  };
};
export type DeleteAudibleAccountsByIdApiResponse =
  /** status 204 The audible account got deleted */ undefined;
export type DeleteAudibleAccountsByIdApiArg = {
  /** The id of the audible account */
  id: string;
};
export type GetAudibleAccountsByIdConnectApiResponse =
  /** status 204 Connection successful, activation bytes generated */ undefined;
export type GetAudibleAccountsByIdConnectApiArg = {
  /** The id of the audible account */
  id: string;
};
export type GetStatisticsApiResponse = /** status 200 Statistics */ Statistics;
export type GetStatisticsApiArg = void;
export type GetWishlistApiResponse =
  /** status 200 Wishlist */ WishlistElement[];
export type GetWishlistApiArg = void;
export type PostWishlistApiResponse = unknown;
export type PostWishlistApiArg = {
  body: {
    id?: string;
    name: string;
    releaseDate?: string;
  };
};
export type DeleteWishlistByIdApiResponse =
  /** status 204 The wishlist element got deleted */ undefined;
export type DeleteWishlistByIdApiArg = {
  /** The id of the wishlist element */
  id: string;
};
export type Version = {
  buildTime: string;
  version: string;
};
export type Language = "english" | "german" | "spanish";
export type User = {
  id: string;
  name: string;
  isAdmin: boolean;
  language: Language;
};
export type UserWithToken = User & {
  sessionToken: string;
};
export type LoginCredentials = {
  username: string;
  password: string;
};
export type ErrorMessage = string;
export type UserWithPassword = User & {
  password: string;
};
export type CreateUser = {
  name: string;
  isAdmin: boolean;
  language: Language;
};
export type UpdateUser = {
  name?: string;
  newPassword?: string;
  language?: string;
};
export type AudiobookUserInfo = {
  audiobookId: string;
  isInterested: boolean;
  progressMSec: number;
  lastListenedTo?: string;
};
export type Author = {
  id: string;
  name: string;
};
export type Narrator = {
  id: string;
  name: string;
};
export type Audiobook = {
  id: string;
  name: string;
  authors: Author[];
  narrators: Narrator[];
  description: string;
  lengthMsec: number;
  timeCreated: string;
  timeUpdated: string;
  fileName: string;
  language: Language;
  audibleASIN: string;
  publicationDate: string;
  audioAvailable: boolean;
};
export type UpdateAudiobook = {
  name?: string;
  authors?: Author[];
  narrators?: Narrator[];
  description?: string;
  lengthMsec?: number;
  language?: Language;
  audibleASIN?: string;
  publicationDate?: string;
};
export type UpdateAudiobookUserInfo = {
  isInterested?: boolean;
  progressMSec?: number;
  lastListenedTo?: string;
};
export type SeriesElement = {
  id: string;
  audiobookId: string;
  position: number;
};
export type Series = {
  id: string;
  name: string;
  description?: string;
  timeCreated: string;
  timeUpdated: string;
  elements: SeriesElement[];
};
export type DownloadToken = {
  token?: string;
  expires?: string;
  audiobookId?: string;
};
export type Resolution = 128 | 512;
export type UpdateSeriesElement = {
  id?: string;
  audiobookId?: string;
  position?: number;
};
export type UpdateSeries = {
  name?: string;
  description?: string;
  elements?: UpdateSeriesElement[];
};
export type AudibleAccount = {
  id: string;
  name: string;
  bookCount: number;
  lastSync: string;
  connected: boolean;
  config: string;
};
export type SeriesStatisticElement = {
  audiobookName: string;
  position: number;
  duration: number;
};
export type Statistics = {
  authorBookCount: {
    [key: string]: number;
  };
  authorBookLength: {
    [key: string]: number;
  };
  seriesStatistics: {
    [key: string]: SeriesStatisticElement[];
  };
};
export type WishlistElement = {
  id: string;
  name: string;
  releaseDate?: string;
  createdBy: string;
  createdAt: string;
};
export const {
  useGetPingQuery,
  useLazyGetPingQuery,
  useGetVersionQuery,
  useLazyGetVersionQuery,
  usePostLoginMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  usePostUsersMutation,
  useGetUsersByIdQuery,
  useLazyGetUsersByIdQuery,
  usePostUsersByIdMutation,
  useDeleteUsersByIdMutation,
  useGetUsersByIdUserInfoQuery,
  useLazyGetUsersByIdUserInfoQuery,
  useGetAuthorsQuery,
  useLazyGetAuthorsQuery,
  usePostAuthorsMutation,
  useGetNarratorsQuery,
  useLazyGetNarratorsQuery,
  usePostNarratorsMutation,
  useGetAudiobooksQuery,
  useLazyGetAudiobooksQuery,
  usePostAudiobooksMutation,
  useGetAudiobooksByIdQuery,
  useLazyGetAudiobooksByIdQuery,
  usePutAudiobooksByIdMutation,
  useDeleteAudiobooksByIdMutation,
  useGetAudiobooksByIdUserInfoQuery,
  useLazyGetAudiobooksByIdUserInfoQuery,
  usePutAudiobooksByIdUserInfoMutation,
  useGetAudiobooksByIdSeriesQuery,
  useLazyGetAudiobooksByIdSeriesQuery,
  useGetDownloadByTokenQuery,
  useLazyGetDownloadByTokenQuery,
  useGetAudiobooksByIdDownloadTokenQuery,
  useLazyGetAudiobooksByIdDownloadTokenQuery,
  usePostAudiobooksByIdAudiofileMutation,
  useGetAudibleAudibleThumbnailByAsinQuery,
  useLazyGetAudibleAudibleThumbnailByAsinQuery,
  useGetAudibleAudibleMetadataByAsinQuery,
  useLazyGetAudibleAudibleMetadataByAsinQuery,
  useGetAudiobooksByIdThumbnailAndResQuery,
  useLazyGetAudiobooksByIdThumbnailAndResQuery,
  usePostAudiobooksByIdThumbnailAndResMutation,
  useGetSeriesQuery,
  useLazyGetSeriesQuery,
  usePostSeriesMutation,
  useGetSeriesByIdQuery,
  useLazyGetSeriesByIdQuery,
  usePutSeriesByIdMutation,
  useDeleteSeriesByIdMutation,
  useGetAudibleAccountsQuery,
  useLazyGetAudibleAccountsQuery,
  usePostAudibleAccountsMutation,
  useDeleteAudibleAccountsByIdMutation,
  useGetAudibleAccountsByIdConnectQuery,
  useLazyGetAudibleAccountsByIdConnectQuery,
  useGetStatisticsQuery,
  useLazyGetStatisticsQuery,
  useGetWishlistQuery,
  useLazyGetWishlistQuery,
  usePostWishlistMutation,
  useDeleteWishlistByIdMutation,
} = injectedRtkApi;
