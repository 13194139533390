import React from "react";

import Select, {MultiValue} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {LabelComponent} from "./LabelComponent";


export function Many2ManyComponent(props: {
    disabled?: boolean,
    className?: string,
    values: any,
    label?: string,
    id: string,
    onChange?: (value: string[]) => void,
    onCreate?: (value: string) => void,
    type?: string,
    options: any,
}) {
    const {
        disabled,
        values,
        label,
        id,
        onChange,
        onCreate,
        options,
    } = props

    return (<>
        {label && <LabelComponent text={label} for={id}/>}

        <CreatableSelect
            value={values}
            isMulti
            isDisabled={disabled}
            isLoading={disabled}
            options={options}
            onChange={(value: MultiValue<string>) => {
                if (!onChange) return;
                // @ts-ignore
                const selectedOptions = value.map((v) => v.value);
                onChange(selectedOptions);
            }}
            onCreateOption={(value: string) => {
                if (!onCreate) return;
                onCreate(value);
            }}
            className="my-react-select-container"
            classNamePrefix="my-react-select"
        />
    </>)
}
