import {cardCls} from "../styles";
import useTranslation from "../i18n";
import React, {useEffect} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import {SeriesStatisticElement, useLazyGetStatisticsQuery} from "../api/generatedApi";
import {LoadingView} from "./ErrorView";
import {Bar} from "react-chartjs-2";
import {setErrorNotification} from "../store/notificationSlice";
import {useDispatch} from "react-redux";
import {ErrorComponent} from "../components/util/ErrorComponent";
import {SeriesStatistics} from "../components/statistic/SeriesStatistics";

interface PropsType {
    children?: React.ReactNode;
    width?: number;
    height?: number;
    resizable?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

type BookCountAuthorType = {
    author: string,
    bookCount: number,
}

type BookLengthAuthorType = {
    author: string,
    bookLength: number,
}

function formatAuthorBookCount(authorBookCount: { [x: string]: number; }): BookCountAuthorType[] {
    const formattedAuthorBookCount = [];
    for (const author in authorBookCount) {
        formattedAuthorBookCount.push({
            'author': author,
            'bookCount': authorBookCount[author],
        })
    }
    formattedAuthorBookCount.sort((a, b) => (a.bookCount > b.bookCount) ? -1 : 1)
    return formattedAuthorBookCount;
}

function formatAuthorBookLength(authorBookLength: { [x: string]: number; }): BookLengthAuthorType[] {
    const formattedAuthorBookLength = [];
    for (const author in authorBookLength) {
        formattedAuthorBookLength.push({
            'author': author,
            'bookLength': Math.round(authorBookLength[author] / 1000  / 60 / 60),
        })
    }
    formattedAuthorBookLength.sort((a, b) => (a.bookLength > b.bookLength) ? -1 : 1)
    return formattedAuthorBookLength;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
    },
};


export function StatisticsView() {
    const t = useTranslation();
    const dispatch = useDispatch();

    const [fetchStatistics, {data: statisticsData, error: statisticsError}] = useLazyGetStatisticsQuery();

    useEffect(() => {
        fetchStatistics();
    }, []);


    useEffect(() => {
        if (statisticsError !== undefined) {
            dispatch(setErrorNotification({text: "Loading Statistics failed"}));
        }
    }, []);

    if (statisticsError) return <ErrorComponent error={statisticsError}/>;

    if (!statisticsData) {
        return <LoadingView description={t("loading")}/>
    }

    const authorBookLength = formatAuthorBookLength(statisticsData.authorBookLength).slice(0, 10)
    const bookLengthData = {
        labels: authorBookLength.map((ele) => ele.author),
        datasets: [
            {
                label: t("statisticsHours"),
                data: authorBookLength.map((ele) => ele.bookLength),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const authorBookCount = formatAuthorBookCount(statisticsData.authorBookCount).slice(0, 10)
    const bookCountData = {
        labels: authorBookCount.map((ele) => ele.author),
        datasets: [
            {
                label: t("statisticsCount"),
                data: authorBookCount.map((ele) => ele.bookCount),
                backgroundColor: 'rgba(144,53,235,0.5)',
            },
        ],
    };



    return (
        <div>
            <div className="">
                <h1 className="text-2xl leading-tight m-3">{t("navBarStatistics")}</h1>
            </div>
            <div className="grid gird-cols-1 xl:grid-cols-2 gap-4 mx-3">
                <div className={`${cardCls} col-span-1 p-3`}>
                    <h2 className="text-xl">{t("statisticsBookLengthByAuthor")}</h2>
                    <Bar options={options} data={bookLengthData} />
                </div>
                <div className={`${cardCls} col-span-1 p-3`}>
                    <h2 className="text-xl">{t("statisticsBookCountByAuthor")}</h2>
                    <Bar options={options} data={bookCountData} />
                </div>
                <div className={`${cardCls} col-span-2 p-3`}>
                    <SeriesStatistics seriesData={statisticsData.seriesStatistics} />
                </div>

            </div>
        </div>
    )
}